// source: sgn/pegbridge/v1/tx.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");
var goog = jspb;
var global = function () {
  if (this) {
    return this;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  return Function("return this")();
}.call(null);

var gogoproto_gogo_pb = require("../../../gogoproto/gogo_pb.js");
goog.object.extend(proto, gogoproto_gogo_pb);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgClaimFee", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgClaimFeeResponse", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgClaimRefund", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgClaimRefundResponse", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgSignMint", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgSignMintResponse", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgSignWithdraw", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgSignWithdrawResponse", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgTriggerSignMint", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw", null, global);
goog.exportSymbol("proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgSignMint = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgSignMint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgSignMint.displayName = "proto.sgn.pegbridge.v1.MsgSignMint";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgSignMintResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgSignMintResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgSignMintResponse.displayName = "proto.sgn.pegbridge.v1.MsgSignMintResponse";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgSignWithdraw, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgSignWithdraw.displayName = "proto.sgn.pegbridge.v1.MsgSignWithdraw";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgSignWithdrawResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgSignWithdrawResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgSignWithdrawResponse.displayName = "proto.sgn.pegbridge.v1.MsgSignWithdrawResponse";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMint = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgTriggerSignMint, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignMint.displayName = "proto.sgn.pegbridge.v1.MsgTriggerSignMint";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse.displayName = "proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.displayName = "proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse.displayName =
    "proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgClaimFee = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgClaimFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgClaimFee.displayName = "proto.sgn.pegbridge.v1.MsgClaimFee";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgClaimFeeResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgClaimFeeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgClaimFeeResponse.displayName = "proto.sgn.pegbridge.v1.MsgClaimFeeResponse";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgClaimRefund = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgClaimRefund, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgClaimRefund.displayName = "proto.sgn.pegbridge.v1.MsgClaimRefund";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.pegbridge.v1.MsgClaimRefundResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.pegbridge.v1.MsgClaimRefundResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.pegbridge.v1.MsgClaimRefundResponse.displayName = "proto.sgn.pegbridge.v1.MsgClaimRefundResponse";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgSignMint.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgSignMint.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgSignMint} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgSignMint.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        mintId: jspb.Message.getFieldWithDefault(msg, 1, ""),
        sender: jspb.Message.getFieldWithDefault(msg, 2, ""),
        signature: msg.getSignature_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgSignMint}
 */
proto.sgn.pegbridge.v1.MsgSignMint.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgSignMint();
  return proto.sgn.pegbridge.v1.MsgSignMint.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgSignMint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgSignMint}
 */
proto.sgn.pegbridge.v1.MsgSignMint.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMintId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSender(value);
        break;
      case 3:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSignature(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgSignMint.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgSignMint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgSignMint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgSignMint.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMintId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(3, f);
  }
};

/**
 * optional string mint_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgSignMint.prototype.getMintId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgSignMint} returns this
 */
proto.sgn.pegbridge.v1.MsgSignMint.prototype.setMintId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string sender = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgSignMint.prototype.getSender = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgSignMint} returns this
 */
proto.sgn.pegbridge.v1.MsgSignMint.prototype.setSender = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional bytes signature = 3;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.MsgSignMint.prototype.getSignature = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * optional bytes signature = 3;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgSignMint.prototype.getSignature_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getSignature()));
};

/**
 * optional bytes signature = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgSignMint.prototype.getSignature_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getSignature()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.MsgSignMint} returns this
 */
proto.sgn.pegbridge.v1.MsgSignMint.prototype.setSignature = function (value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgSignMintResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgSignMintResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgSignMintResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgSignMintResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgSignMintResponse}
 */
proto.sgn.pegbridge.v1.MsgSignMintResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgSignMintResponse();
  return proto.sgn.pegbridge.v1.MsgSignMintResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgSignMintResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgSignMintResponse}
 */
proto.sgn.pegbridge.v1.MsgSignMintResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgSignMintResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgSignMintResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgSignMintResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgSignMintResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgSignWithdraw.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgSignWithdraw.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgSignWithdraw} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgSignWithdraw.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        withdrawId: jspb.Message.getFieldWithDefault(msg, 1, ""),
        sender: jspb.Message.getFieldWithDefault(msg, 2, ""),
        signature: msg.getSignature_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgSignWithdraw}
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgSignWithdraw();
  return proto.sgn.pegbridge.v1.MsgSignWithdraw.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgSignWithdraw} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgSignWithdraw}
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setWithdrawId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSender(value);
        break;
      case 3:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSignature(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgSignWithdraw.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgSignWithdraw} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getWithdrawId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(3, f);
  }
};

/**
 * optional string withdraw_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.prototype.getWithdrawId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgSignWithdraw} returns this
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.prototype.setWithdrawId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string sender = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.prototype.getSender = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgSignWithdraw} returns this
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.prototype.setSender = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional bytes signature = 3;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.prototype.getSignature = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * optional bytes signature = 3;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.prototype.getSignature_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getSignature()));
};

/**
 * optional bytes signature = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.prototype.getSignature_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getSignature()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.MsgSignWithdraw} returns this
 */
proto.sgn.pegbridge.v1.MsgSignWithdraw.prototype.setSignature = function (value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgSignWithdrawResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgSignWithdrawResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgSignWithdrawResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgSignWithdrawResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgSignWithdrawResponse}
 */
proto.sgn.pegbridge.v1.MsgSignWithdrawResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgSignWithdrawResponse();
  return proto.sgn.pegbridge.v1.MsgSignWithdrawResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgSignWithdrawResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgSignWithdrawResponse}
 */
proto.sgn.pegbridge.v1.MsgSignWithdrawResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgSignWithdrawResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgSignWithdrawResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgSignWithdrawResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgSignWithdrawResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignMint.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgTriggerSignMint.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignMint} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignMint.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        mintId: jspb.Message.getFieldWithDefault(msg, 1, ""),
        sender: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignMint}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMint.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgTriggerSignMint();
  return proto.sgn.pegbridge.v1.MsgTriggerSignMint.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignMint} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignMint}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMint.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setMintId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSender(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMint.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgTriggerSignMint.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignMint} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMint.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getMintId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string mint_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMint.prototype.getMintId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignMint} returns this
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMint.prototype.setMintId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string sender = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMint.prototype.getSender = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignMint} returns this
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMint.prototype.setSender = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse();
  return proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgTriggerSignMintResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        withdrawId: jspb.Message.getFieldWithDefault(msg, 1, ""),
        sender: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw();
  return proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setWithdrawId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSender(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getWithdrawId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string withdraw_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.prototype.getWithdrawId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw} returns this
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.prototype.setWithdrawId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string sender = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.prototype.getSender = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw} returns this
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdraw.prototype.setSender = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse();
  return proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgTriggerSignWithdrawResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgClaimFee.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgClaimFee.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgClaimFee} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgClaimFee.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        delegatorAddress: jspb.Message.getFieldWithDefault(msg, 1, ""),
        chainId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        tokenAddress: jspb.Message.getFieldWithDefault(msg, 3, ""),
        nonce: jspb.Message.getFieldWithDefault(msg, 4, 0),
        signature: msg.getSignature_asB64(),
        sender: jspb.Message.getFieldWithDefault(msg, 6, ""),
        isValidator: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFee}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgClaimFee();
  return proto.sgn.pegbridge.v1.MsgClaimFee.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgClaimFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFee}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDelegatorAddress(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setChainId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setTokenAddress(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setNonce(value);
        break;
      case 5:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSignature(value);
        break;
      case 6:
        var value = /** @type {string} */ (reader.readString());
        msg.setSender(value);
        break;
      case 7:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setIsValidator(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgClaimFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgClaimFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgClaimFee.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDelegatorAddress();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(2, f);
  }
  f = message.getTokenAddress();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getNonce();
  if (f !== 0) {
    writer.writeUint64(4, f);
  }
  f = message.getSignature_asU8();
  if (f.length > 0) {
    writer.writeBytes(5, f);
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(6, f);
  }
  f = message.getIsValidator();
  if (f) {
    writer.writeBool(7, f);
  }
};

/**
 * optional string delegator_address = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.getDelegatorAddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFee} returns this
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.setDelegatorAddress = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional uint64 chain_id = 2;
 * @return {number}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.getChainId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFee} returns this
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.setChainId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string token_address = 3;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.getTokenAddress = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFee} returns this
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.setTokenAddress = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional uint64 nonce = 4;
 * @return {number}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.getNonce = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFee} returns this
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.setNonce = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional bytes signature = 5;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.getSignature = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * optional bytes signature = 5;
 * This is a type-conversion wrapper around `getSignature()`
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.getSignature_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getSignature()));
};

/**
 * optional bytes signature = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignature()`
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.getSignature_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getSignature()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFee} returns this
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.setSignature = function (value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};

/**
 * optional string sender = 6;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.getSender = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFee} returns this
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.setSender = function (value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};

/**
 * optional bool is_validator = 7;
 * @return {boolean}
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.getIsValidator = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};

/**
 * @param {boolean} value
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFee} returns this
 */
proto.sgn.pegbridge.v1.MsgClaimFee.prototype.setIsValidator = function (value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgClaimFeeResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgClaimFeeResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgClaimFeeResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgClaimFeeResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFeeResponse}
 */
proto.sgn.pegbridge.v1.MsgClaimFeeResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgClaimFeeResponse();
  return proto.sgn.pegbridge.v1.MsgClaimFeeResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgClaimFeeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgClaimFeeResponse}
 */
proto.sgn.pegbridge.v1.MsgClaimFeeResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgClaimFeeResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgClaimFeeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgClaimFeeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgClaimFeeResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgClaimRefund.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgClaimRefund.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgClaimRefund} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgClaimRefund.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        refId: jspb.Message.getFieldWithDefault(msg, 1, ""),
        sender: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgClaimRefund}
 */
proto.sgn.pegbridge.v1.MsgClaimRefund.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgClaimRefund();
  return proto.sgn.pegbridge.v1.MsgClaimRefund.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgClaimRefund} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgClaimRefund}
 */
proto.sgn.pegbridge.v1.MsgClaimRefund.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setRefId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setSender(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgClaimRefund.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgClaimRefund.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgClaimRefund} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgClaimRefund.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRefId();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getSender();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional string ref_id = 1;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgClaimRefund.prototype.getRefId = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgClaimRefund} returns this
 */
proto.sgn.pegbridge.v1.MsgClaimRefund.prototype.setRefId = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string sender = 2;
 * @return {string}
 */
proto.sgn.pegbridge.v1.MsgClaimRefund.prototype.getSender = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.pegbridge.v1.MsgClaimRefund} returns this
 */
proto.sgn.pegbridge.v1.MsgClaimRefund.prototype.setSender = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.pegbridge.v1.MsgClaimRefundResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.pegbridge.v1.MsgClaimRefundResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.pegbridge.v1.MsgClaimRefundResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.pegbridge.v1.MsgClaimRefundResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {};

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.pegbridge.v1.MsgClaimRefundResponse}
 */
proto.sgn.pegbridge.v1.MsgClaimRefundResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.pegbridge.v1.MsgClaimRefundResponse();
  return proto.sgn.pegbridge.v1.MsgClaimRefundResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.pegbridge.v1.MsgClaimRefundResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.pegbridge.v1.MsgClaimRefundResponse}
 */
proto.sgn.pegbridge.v1.MsgClaimRefundResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.pegbridge.v1.MsgClaimRefundResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.pegbridge.v1.MsgClaimRefundResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.pegbridge.v1.MsgClaimRefundResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.pegbridge.v1.MsgClaimRefundResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
};

goog.object.extend(exports, proto.sgn.pegbridge.v1);
