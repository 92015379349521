// source: sgn/health/v1/health.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");
var goog = jspb;
var global = function () {
  if (this) {
    return this;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  return Function("return this")();
}.call(null);

goog.exportSymbol("proto.sgn.health.v1.BaseFeeDistributionHistory", null, global);
goog.exportSymbol("proto.sgn.health.v1.BridgeType", null, global);
goog.exportSymbol("proto.sgn.health.v1.ErrCode", null, global);
goog.exportSymbol("proto.sgn.health.v1.ErrMsg", null, global);
goog.exportSymbol("proto.sgn.health.v1.LPFeeEarningHistory", null, global);
goog.exportSymbol("proto.sgn.health.v1.LPFeeEarningLog", null, global);
goog.exportSymbol("proto.sgn.health.v1.ReportSgnAnalyticsRequest", null, global);
goog.exportSymbol("proto.sgn.health.v1.ReportSgnAnalyticsResponse", null, global);
goog.exportSymbol("proto.sgn.health.v1.SgnAnalyticsReport", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.health.v1.ReportSgnAnalyticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.health.v1.ReportSgnAnalyticsRequest.displayName = "proto.sgn.health.v1.ReportSgnAnalyticsRequest";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.health.v1.SgnAnalyticsReport = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.health.v1.SgnAnalyticsReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.health.v1.SgnAnalyticsReport.displayName = "proto.sgn.health.v1.SgnAnalyticsReport";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.health.v1.BaseFeeDistributionHistory = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.health.v1.BaseFeeDistributionHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.health.v1.BaseFeeDistributionHistory.displayName = "proto.sgn.health.v1.BaseFeeDistributionHistory";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.health.v1.LPFeeEarningHistory = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.health.v1.LPFeeEarningHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.health.v1.LPFeeEarningHistory.displayName = "proto.sgn.health.v1.LPFeeEarningHistory";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.health.v1.LPFeeEarningLog = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.health.v1.LPFeeEarningLog, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.health.v1.LPFeeEarningLog.displayName = "proto.sgn.health.v1.LPFeeEarningLog";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.health.v1.ReportSgnAnalyticsResponse = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.health.v1.ReportSgnAnalyticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.health.v1.ReportSgnAnalyticsResponse.displayName = "proto.sgn.health.v1.ReportSgnAnalyticsResponse";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.health.v1.ErrMsg = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.health.v1.ErrMsg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.health.v1.ErrMsg.displayName = "proto.sgn.health.v1.ErrMsg";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.health.v1.ReportSgnAnalyticsRequest.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.health.v1.ReportSgnAnalyticsRequest.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.health.v1.ReportSgnAnalyticsRequest} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.health.v1.ReportSgnAnalyticsRequest.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        report: msg.getReport_asB64(),
        sig: msg.getSig_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.health.v1.ReportSgnAnalyticsRequest}
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.health.v1.ReportSgnAnalyticsRequest();
  return proto.sgn.health.v1.ReportSgnAnalyticsRequest.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.health.v1.ReportSgnAnalyticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.health.v1.ReportSgnAnalyticsRequest}
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setReport(value);
        break;
      case 2:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSig(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.health.v1.ReportSgnAnalyticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.health.v1.ReportSgnAnalyticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getReport_asU8();
  if (f.length > 0) {
    writer.writeBytes(1, f);
  }
  f = message.getSig_asU8();
  if (f.length > 0) {
    writer.writeBytes(2, f);
  }
};

/**
 * optional bytes report = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.prototype.getReport = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * optional bytes report = 1;
 * This is a type-conversion wrapper around `getReport()`
 * @return {string}
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.prototype.getReport_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getReport()));
};

/**
 * optional bytes report = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReport()`
 * @return {!Uint8Array}
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.prototype.getReport_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getReport()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.health.v1.ReportSgnAnalyticsRequest} returns this
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.prototype.setReport = function (value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes sig = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.prototype.getSig = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * optional bytes sig = 2;
 * This is a type-conversion wrapper around `getSig()`
 * @return {string}
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.prototype.getSig_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getSig()));
};

/**
 * optional bytes sig = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSig()`
 * @return {!Uint8Array}
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.prototype.getSig_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getSig()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.health.v1.ReportSgnAnalyticsRequest} returns this
 */
proto.sgn.health.v1.ReportSgnAnalyticsRequest.prototype.setSig = function (value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.health.v1.SgnAnalyticsReport.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.health.v1.SgnAnalyticsReport.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.health.v1.SgnAnalyticsReport} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.health.v1.SgnAnalyticsReport.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        timestamp: jspb.Message.getFieldWithDefault(msg, 1, 0),
        blockNumsMap: (f = msg.getBlockNumsMap()) ? f.toObject(includeInstance, undefined) : [],
        sgndVersion: jspb.Message.getFieldWithDefault(msg, 3, ""),
        lpFeeEarningHistoriesMap: (f = msg.getLpFeeEarningHistoriesMap())
          ? f.toObject(includeInstance, proto.sgn.health.v1.LPFeeEarningHistory.toObject)
          : [],
        baseFeeDistributionHistoriesMap: (f = msg.getBaseFeeDistributionHistoriesMap())
          ? f.toObject(includeInstance, proto.sgn.health.v1.BaseFeeDistributionHistory.toObject)
          : [],
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.health.v1.SgnAnalyticsReport}
 */
proto.sgn.health.v1.SgnAnalyticsReport.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.health.v1.SgnAnalyticsReport();
  return proto.sgn.health.v1.SgnAnalyticsReport.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.health.v1.SgnAnalyticsReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.health.v1.SgnAnalyticsReport}
 */
proto.sgn.health.v1.SgnAnalyticsReport.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setTimestamp(value);
        break;
      case 2:
        var value = msg.getBlockNumsMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readUint64,
            null,
            "",
            0,
          );
        });
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setSgndVersion(value);
        break;
      case 4:
        var value = msg.getLpFeeEarningHistoriesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readUint64,
            jspb.BinaryReader.prototype.readMessage,
            proto.sgn.health.v1.LPFeeEarningHistory.deserializeBinaryFromReader,
            0,
            new proto.sgn.health.v1.LPFeeEarningHistory(),
          );
        });
        break;
      case 5:
        var value = msg.getBaseFeeDistributionHistoriesMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readUint64,
            jspb.BinaryReader.prototype.readMessage,
            proto.sgn.health.v1.BaseFeeDistributionHistory.deserializeBinaryFromReader,
            0,
            new proto.sgn.health.v1.BaseFeeDistributionHistory(),
          );
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.health.v1.SgnAnalyticsReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.health.v1.SgnAnalyticsReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.health.v1.SgnAnalyticsReport.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getBlockNumsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeUint64);
  }
  f = message.getSgndVersion();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getLpFeeEarningHistoriesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      4,
      writer,
      jspb.BinaryWriter.prototype.writeUint64,
      jspb.BinaryWriter.prototype.writeMessage,
      proto.sgn.health.v1.LPFeeEarningHistory.serializeBinaryToWriter,
    );
  }
  f = message.getBaseFeeDistributionHistoriesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      5,
      writer,
      jspb.BinaryWriter.prototype.writeUint64,
      jspb.BinaryWriter.prototype.writeMessage,
      proto.sgn.health.v1.BaseFeeDistributionHistory.serializeBinaryToWriter,
    );
  }
};

/**
 * optional uint64 timestamp = 1;
 * @return {number}
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.getTimestamp = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.health.v1.SgnAnalyticsReport} returns this
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.setTimestamp = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * map<string, uint64> block_nums = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,number>}
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.getBlockNumsMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,number>} */ (jspb.Message.getMapField(this, 2, opt_noLazyCreate, null));
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sgn.health.v1.SgnAnalyticsReport} returns this
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.clearBlockNumsMap = function () {
  this.getBlockNumsMap().clear();
  return this;
};

/**
 * optional string sgnd_version = 3;
 * @return {string}
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.getSgndVersion = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.health.v1.SgnAnalyticsReport} returns this
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.setSgndVersion = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * map<uint64, LPFeeEarningHistory> lp_fee_earning_histories = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.sgn.health.v1.LPFeeEarningHistory>}
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.getLpFeeEarningHistoriesMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.sgn.health.v1.LPFeeEarningHistory>} */ (
    jspb.Message.getMapField(this, 4, opt_noLazyCreate, proto.sgn.health.v1.LPFeeEarningHistory)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sgn.health.v1.SgnAnalyticsReport} returns this
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.clearLpFeeEarningHistoriesMap = function () {
  this.getLpFeeEarningHistoriesMap().clear();
  return this;
};

/**
 * map<uint64, BaseFeeDistributionHistory> base_fee_distribution_histories = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,!proto.sgn.health.v1.BaseFeeDistributionHistory>}
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.getBaseFeeDistributionHistoriesMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<number,!proto.sgn.health.v1.BaseFeeDistributionHistory>} */ (
    jspb.Message.getMapField(this, 5, opt_noLazyCreate, proto.sgn.health.v1.BaseFeeDistributionHistory)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sgn.health.v1.SgnAnalyticsReport} returns this
 */
proto.sgn.health.v1.SgnAnalyticsReport.prototype.clearBaseFeeDistributionHistoriesMap = function () {
  this.getBaseFeeDistributionHistoriesMap().clear();
  return this;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.health.v1.BaseFeeDistributionHistory.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.health.v1.BaseFeeDistributionHistory} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.health.v1.BaseFeeDistributionHistory.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        bridgeType: jspb.Message.getFieldWithDefault(msg, 1, 0),
        baseFeeReceiverAddr: jspb.Message.getFieldWithDefault(msg, 2, ""),
        baseFeeAmt: jspb.Message.getFieldWithDefault(msg, 3, ""),
        tokenSymbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
        tokenDecimal: jspb.Message.getFieldWithDefault(msg, 5, 0),
        srcChainId: jspb.Message.getFieldWithDefault(msg, 6, 0),
        dstChainId: jspb.Message.getFieldWithDefault(msg, 7, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.health.v1.BaseFeeDistributionHistory}
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.health.v1.BaseFeeDistributionHistory();
  return proto.sgn.health.v1.BaseFeeDistributionHistory.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.health.v1.BaseFeeDistributionHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.health.v1.BaseFeeDistributionHistory}
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.sgn.health.v1.BridgeType} */ (reader.readEnum());
        msg.setBridgeType(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setBaseFeeReceiverAddr(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setBaseFeeAmt(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setTokenSymbol(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setTokenDecimal(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setSrcChainId(value);
        break;
      case 7:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setDstChainId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.health.v1.BaseFeeDistributionHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.health.v1.BaseFeeDistributionHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getBridgeType();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getBaseFeeReceiverAddr();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getBaseFeeAmt();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getTokenSymbol();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
  f = message.getTokenDecimal();
  if (f !== 0) {
    writer.writeUint32(5, f);
  }
  f = message.getSrcChainId();
  if (f !== 0) {
    writer.writeUint64(6, f);
  }
  f = message.getDstChainId();
  if (f !== 0) {
    writer.writeUint64(7, f);
  }
};

/**
 * optional BridgeType bridge_type = 1;
 * @return {!proto.sgn.health.v1.BridgeType}
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.getBridgeType = function () {
  return /** @type {!proto.sgn.health.v1.BridgeType} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {!proto.sgn.health.v1.BridgeType} value
 * @return {!proto.sgn.health.v1.BaseFeeDistributionHistory} returns this
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.setBridgeType = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional string base_fee_receiver_addr = 2;
 * @return {string}
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.getBaseFeeReceiverAddr = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.health.v1.BaseFeeDistributionHistory} returns this
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.setBaseFeeReceiverAddr = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string base_fee_amt = 3;
 * @return {string}
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.getBaseFeeAmt = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.health.v1.BaseFeeDistributionHistory} returns this
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.setBaseFeeAmt = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional string token_symbol = 4;
 * @return {string}
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.getTokenSymbol = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.health.v1.BaseFeeDistributionHistory} returns this
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.setTokenSymbol = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

/**
 * optional uint32 token_decimal = 5;
 * @return {number}
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.getTokenDecimal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.health.v1.BaseFeeDistributionHistory} returns this
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.setTokenDecimal = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional uint64 src_chain_id = 6;
 * @return {number}
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.getSrcChainId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.health.v1.BaseFeeDistributionHistory} returns this
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.setSrcChainId = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional uint64 dst_chain_id = 7;
 * @return {number}
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.getDstChainId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.health.v1.BaseFeeDistributionHistory} returns this
 */
proto.sgn.health.v1.BaseFeeDistributionHistory.prototype.setDstChainId = function (value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.health.v1.LPFeeEarningHistory.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.health.v1.LPFeeEarningHistory.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.health.v1.LPFeeEarningHistory} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.health.v1.LPFeeEarningHistory.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dstTokenAddr: jspb.Message.getFieldWithDefault(msg, 1, ""),
        dstChainId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        logsMap: (f = msg.getLogsMap())
          ? f.toObject(includeInstance, proto.sgn.health.v1.LPFeeEarningLog.toObject)
          : [],
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.health.v1.LPFeeEarningHistory}
 */
proto.sgn.health.v1.LPFeeEarningHistory.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.health.v1.LPFeeEarningHistory();
  return proto.sgn.health.v1.LPFeeEarningHistory.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.health.v1.LPFeeEarningHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.health.v1.LPFeeEarningHistory}
 */
proto.sgn.health.v1.LPFeeEarningHistory.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDstTokenAddr(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setDstChainId(value);
        break;
      case 3:
        var value = msg.getLogsMap();
        reader.readMessage(value, function (message, reader) {
          jspb.Map.deserializeBinary(
            message,
            reader,
            jspb.BinaryReader.prototype.readString,
            jspb.BinaryReader.prototype.readMessage,
            proto.sgn.health.v1.LPFeeEarningLog.deserializeBinaryFromReader,
            "",
            new proto.sgn.health.v1.LPFeeEarningLog(),
          );
        });
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.health.v1.LPFeeEarningHistory.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.health.v1.LPFeeEarningHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.health.v1.LPFeeEarningHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.health.v1.LPFeeEarningHistory.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDstTokenAddr();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getDstChainId();
  if (f !== 0) {
    writer.writeUint64(2, f);
  }
  f = message.getLogsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(
      3,
      writer,
      jspb.BinaryWriter.prototype.writeString,
      jspb.BinaryWriter.prototype.writeMessage,
      proto.sgn.health.v1.LPFeeEarningLog.serializeBinaryToWriter,
    );
  }
};

/**
 * optional string dst_token_addr = 1;
 * @return {string}
 */
proto.sgn.health.v1.LPFeeEarningHistory.prototype.getDstTokenAddr = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.health.v1.LPFeeEarningHistory} returns this
 */
proto.sgn.health.v1.LPFeeEarningHistory.prototype.setDstTokenAddr = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional uint64 dst_chain_id = 2;
 * @return {number}
 */
proto.sgn.health.v1.LPFeeEarningHistory.prototype.getDstChainId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.health.v1.LPFeeEarningHistory} returns this
 */
proto.sgn.health.v1.LPFeeEarningHistory.prototype.setDstChainId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * map<string, LPFeeEarningLog> logs = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.sgn.health.v1.LPFeeEarningLog>}
 */
proto.sgn.health.v1.LPFeeEarningHistory.prototype.getLogsMap = function (opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.sgn.health.v1.LPFeeEarningLog>} */ (
    jspb.Message.getMapField(this, 3, opt_noLazyCreate, proto.sgn.health.v1.LPFeeEarningLog)
  );
};

/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.sgn.health.v1.LPFeeEarningHistory} returns this
 */
proto.sgn.health.v1.LPFeeEarningHistory.prototype.clearLogsMap = function () {
  this.getLogsMap().clear();
  return this;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.health.v1.LPFeeEarningLog.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.health.v1.LPFeeEarningLog.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.health.v1.LPFeeEarningLog} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.health.v1.LPFeeEarningLog.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        dstChainLiquidityUsed: jspb.Message.getFieldWithDefault(msg, 1, ""),
        earnedFee: jspb.Message.getFieldWithDefault(msg, 2, ""),
        dstChainLiquidityRemained: jspb.Message.getFieldWithDefault(msg, 3, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.health.v1.LPFeeEarningLog}
 */
proto.sgn.health.v1.LPFeeEarningLog.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.health.v1.LPFeeEarningLog();
  return proto.sgn.health.v1.LPFeeEarningLog.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.health.v1.LPFeeEarningLog} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.health.v1.LPFeeEarningLog}
 */
proto.sgn.health.v1.LPFeeEarningLog.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setDstChainLiquidityUsed(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEarnedFee(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setDstChainLiquidityRemained(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.health.v1.LPFeeEarningLog.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.health.v1.LPFeeEarningLog.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.health.v1.LPFeeEarningLog} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.health.v1.LPFeeEarningLog.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getDstChainLiquidityUsed();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getEarnedFee();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getDstChainLiquidityRemained();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
};

/**
 * optional string dst_chain_liquidity_used = 1;
 * @return {string}
 */
proto.sgn.health.v1.LPFeeEarningLog.prototype.getDstChainLiquidityUsed = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.health.v1.LPFeeEarningLog} returns this
 */
proto.sgn.health.v1.LPFeeEarningLog.prototype.setDstChainLiquidityUsed = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string earned_fee = 2;
 * @return {string}
 */
proto.sgn.health.v1.LPFeeEarningLog.prototype.getEarnedFee = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.health.v1.LPFeeEarningLog} returns this
 */
proto.sgn.health.v1.LPFeeEarningLog.prototype.setEarnedFee = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional string dst_chain_liquidity_remained = 3;
 * @return {string}
 */
proto.sgn.health.v1.LPFeeEarningLog.prototype.getDstChainLiquidityRemained = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.health.v1.LPFeeEarningLog} returns this
 */
proto.sgn.health.v1.LPFeeEarningLog.prototype.setDstChainLiquidityRemained = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.health.v1.ReportSgnAnalyticsResponse.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.health.v1.ReportSgnAnalyticsResponse.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.health.v1.ReportSgnAnalyticsResponse} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.health.v1.ReportSgnAnalyticsResponse.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        err: (f = msg.getErr()) && proto.sgn.health.v1.ErrMsg.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.health.v1.ReportSgnAnalyticsResponse}
 */
proto.sgn.health.v1.ReportSgnAnalyticsResponse.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.health.v1.ReportSgnAnalyticsResponse();
  return proto.sgn.health.v1.ReportSgnAnalyticsResponse.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.health.v1.ReportSgnAnalyticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.health.v1.ReportSgnAnalyticsResponse}
 */
proto.sgn.health.v1.ReportSgnAnalyticsResponse.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.sgn.health.v1.ErrMsg();
        reader.readMessage(value, proto.sgn.health.v1.ErrMsg.deserializeBinaryFromReader);
        msg.setErr(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.health.v1.ReportSgnAnalyticsResponse.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.health.v1.ReportSgnAnalyticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.health.v1.ReportSgnAnalyticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.health.v1.ReportSgnAnalyticsResponse.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getErr();
  if (f != null) {
    writer.writeMessage(1, f, proto.sgn.health.v1.ErrMsg.serializeBinaryToWriter);
  }
};

/**
 * optional ErrMsg err = 1;
 * @return {?proto.sgn.health.v1.ErrMsg}
 */
proto.sgn.health.v1.ReportSgnAnalyticsResponse.prototype.getErr = function () {
  return /** @type{?proto.sgn.health.v1.ErrMsg} */ (jspb.Message.getWrapperField(this, proto.sgn.health.v1.ErrMsg, 1));
};

/**
 * @param {?proto.sgn.health.v1.ErrMsg|undefined} value
 * @return {!proto.sgn.health.v1.ReportSgnAnalyticsResponse} returns this
 */
proto.sgn.health.v1.ReportSgnAnalyticsResponse.prototype.setErr = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.health.v1.ReportSgnAnalyticsResponse} returns this
 */
proto.sgn.health.v1.ReportSgnAnalyticsResponse.prototype.clearErr = function () {
  return this.setErr(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.health.v1.ReportSgnAnalyticsResponse.prototype.hasErr = function () {
  return jspb.Message.getField(this, 1) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.health.v1.ErrMsg.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.health.v1.ErrMsg.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.health.v1.ErrMsg} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.health.v1.ErrMsg.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        code: jspb.Message.getFieldWithDefault(msg, 1, 0),
        msg: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.health.v1.ErrMsg}
 */
proto.sgn.health.v1.ErrMsg.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.health.v1.ErrMsg();
  return proto.sgn.health.v1.ErrMsg.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.health.v1.ErrMsg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.health.v1.ErrMsg}
 */
proto.sgn.health.v1.ErrMsg.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!proto.sgn.health.v1.ErrCode} */ (reader.readEnum());
        msg.setCode(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setMsg(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.health.v1.ErrMsg.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.health.v1.ErrMsg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.health.v1.ErrMsg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.health.v1.ErrMsg.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0.0) {
    writer.writeEnum(1, f);
  }
  f = message.getMsg();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional ErrCode code = 1;
 * @return {!proto.sgn.health.v1.ErrCode}
 */
proto.sgn.health.v1.ErrMsg.prototype.getCode = function () {
  return /** @type {!proto.sgn.health.v1.ErrCode} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {!proto.sgn.health.v1.ErrCode} value
 * @return {!proto.sgn.health.v1.ErrMsg} returns this
 */
proto.sgn.health.v1.ErrMsg.prototype.setCode = function (value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};

/**
 * optional string msg = 2;
 * @return {string}
 */
proto.sgn.health.v1.ErrMsg.prototype.getMsg = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.health.v1.ErrMsg} returns this
 */
proto.sgn.health.v1.ErrMsg.prototype.setMsg = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * @enum {number}
 */
proto.sgn.health.v1.BridgeType = {
  BRIDGE_TYPE_UNDEFINED: 0,
  BRIDGE_TYPE_LIQUIDITY: 1,
  BRIDGE_TYPE_PEGGED: 2,
};

/**
 * @enum {number}
 */
proto.sgn.health.v1.ErrCode = {
  ERROR_CODE_UNDEFINED: 0,
  ERROR_CODE_COMMON: 500,
  ERROR_NO_TOKEN_ON_DST_CHAIN: 1001,
  ERROR_NO_TOKEN_ON_SRC_CHAIN: 1002,
  ERROR_INIT_WITHDRAW_FAILED: 1003,
  ERROR_CODE_NO_ENOUGH_TOKEN_ON_DST_CHAIN: 1004,
};

goog.object.extend(exports, proto.sgn.health.v1);
