// source: sgn/cbridge/v1/cbridge.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require("google-protobuf");
var goog = jspb;
var global = function () {
  if (this) {
    return this;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  return Function("return this")();
}.call(null);

var cosmos_proto_cosmos_pb = require("../../../cosmos_proto/cosmos_pb.js");
goog.object.extend(proto, cosmos_proto_cosmos_pb);
var gogoproto_gogo_pb = require("../../../gogoproto/gogo_pb.js");
goog.object.extend(proto, gogoproto_gogo_pb);
var google_protobuf_duration_pb = require("google-protobuf/google/protobuf/duration_pb.js");
goog.object.extend(proto, google_protobuf_duration_pb);
var sgn_common_v1_common_pb = require("../../../sgn/common/v1/common_pb.js");
goog.object.extend(proto, sgn_common_v1_common_pb);
goog.exportSymbol("proto.sgn.cbridge.v1.AddrSig", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.AssetPrice", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.CbrConfig", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.CbrPrice", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.CbrProposal", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.ChainAsset", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.ChainPair", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.ChainSigners", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.GasPrice", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.LatestSigners", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.OnChainEvent", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.Params", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.PerChainPairAssetOverride", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.RelayGasCostParam", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.RelayOnChain", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.Signer", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.WithdrawDetail", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.WithdrawOnchain", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.XferRelay", null, global);
goog.exportSymbol("proto.sgn.cbridge.v1.XferStatus", null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.Params = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.Params, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.Params.displayName = "proto.sgn.cbridge.v1.Params";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.CbrConfig = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.cbridge.v1.CbrConfig.repeatedFields_, null);
};
goog.inherits(proto.sgn.cbridge.v1.CbrConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.CbrConfig.displayName = "proto.sgn.cbridge.v1.CbrConfig";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.CbrPrice = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.cbridge.v1.CbrPrice.repeatedFields_, null);
};
goog.inherits(proto.sgn.cbridge.v1.CbrPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.CbrPrice.displayName = "proto.sgn.cbridge.v1.CbrPrice";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.RelayGasCostParam = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.RelayGasCostParam, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.RelayGasCostParam.displayName = "proto.sgn.cbridge.v1.RelayGasCostParam";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.AssetPrice = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.cbridge.v1.AssetPrice.repeatedFields_, null);
};
goog.inherits(proto.sgn.cbridge.v1.AssetPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.AssetPrice.displayName = "proto.sgn.cbridge.v1.AssetPrice";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.GasPrice = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.GasPrice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.GasPrice.displayName = "proto.sgn.cbridge.v1.GasPrice";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.ChainAsset = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.ChainAsset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.ChainAsset.displayName = "proto.sgn.cbridge.v1.ChainAsset";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.ChainPair = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.ChainPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.ChainPair.displayName = "proto.sgn.cbridge.v1.ChainPair";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.PerChainPairAssetOverride, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.PerChainPairAssetOverride.displayName = "proto.sgn.cbridge.v1.PerChainPairAssetOverride";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.CbrProposal = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.CbrProposal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.CbrProposal.displayName = "proto.sgn.cbridge.v1.CbrProposal";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.OnChainEvent = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.OnChainEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.OnChainEvent.displayName = "proto.sgn.cbridge.v1.OnChainEvent";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.RelayOnChain = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.RelayOnChain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.RelayOnChain.displayName = "proto.sgn.cbridge.v1.RelayOnChain";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.WithdrawOnchain = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.WithdrawOnchain, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.WithdrawOnchain.displayName = "proto.sgn.cbridge.v1.WithdrawOnchain";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.XferRelay = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.cbridge.v1.XferRelay.repeatedFields_, null);
};
goog.inherits(proto.sgn.cbridge.v1.XferRelay, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.XferRelay.displayName = "proto.sgn.cbridge.v1.XferRelay";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.WithdrawDetail = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.cbridge.v1.WithdrawDetail.repeatedFields_, null);
};
goog.inherits(proto.sgn.cbridge.v1.WithdrawDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.WithdrawDetail.displayName = "proto.sgn.cbridge.v1.WithdrawDetail";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.AddrSig = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.AddrSig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.AddrSig.displayName = "proto.sgn.cbridge.v1.AddrSig";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.Signer = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.sgn.cbridge.v1.Signer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.Signer.displayName = "proto.sgn.cbridge.v1.Signer";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.ChainSigners = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.cbridge.v1.ChainSigners.repeatedFields_, null);
};
goog.inherits(proto.sgn.cbridge.v1.ChainSigners, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.ChainSigners.displayName = "proto.sgn.cbridge.v1.ChainSigners";
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.sgn.cbridge.v1.LatestSigners = function (opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.sgn.cbridge.v1.LatestSigners.repeatedFields_, null);
};
goog.inherits(proto.sgn.cbridge.v1.LatestSigners, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.sgn.cbridge.v1.LatestSigners.displayName = "proto.sgn.cbridge.v1.LatestSigners";
}

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.Params.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.Params.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.Params} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.Params.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        signerUpdateDuration:
          (f = msg.getSignerUpdateDuration()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
        signAgainCoolDownDuration:
          (f = msg.getSignAgainCoolDownDuration()) && google_protobuf_duration_pb.Duration.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.Params}
 */
proto.sgn.cbridge.v1.Params.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.Params();
  return proto.sgn.cbridge.v1.Params.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.Params} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.Params}
 */
proto.sgn.cbridge.v1.Params.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(value, google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
        msg.setSignerUpdateDuration(value);
        break;
      case 2:
        var value = new google_protobuf_duration_pb.Duration();
        reader.readMessage(value, google_protobuf_duration_pb.Duration.deserializeBinaryFromReader);
        msg.setSignAgainCoolDownDuration(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.Params.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.Params.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.Params} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.Params.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSignerUpdateDuration();
  if (f != null) {
    writer.writeMessage(1, f, google_protobuf_duration_pb.Duration.serializeBinaryToWriter);
  }
  f = message.getSignAgainCoolDownDuration();
  if (f != null) {
    writer.writeMessage(2, f, google_protobuf_duration_pb.Duration.serializeBinaryToWriter);
  }
};

/**
 * optional google.protobuf.Duration signer_update_duration = 1;
 * @return {?proto.google.protobuf.Duration}
 */
proto.sgn.cbridge.v1.Params.prototype.getSignerUpdateDuration = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 1)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.sgn.cbridge.v1.Params} returns this
 */
proto.sgn.cbridge.v1.Params.prototype.setSignerUpdateDuration = function (value) {
  return jspb.Message.setWrapperField(this, 1, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.cbridge.v1.Params} returns this
 */
proto.sgn.cbridge.v1.Params.prototype.clearSignerUpdateDuration = function () {
  return this.setSignerUpdateDuration(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.cbridge.v1.Params.prototype.hasSignerUpdateDuration = function () {
  return jspb.Message.getField(this, 1) != null;
};

/**
 * optional google.protobuf.Duration sign_again_cool_down_duration = 2;
 * @return {?proto.google.protobuf.Duration}
 */
proto.sgn.cbridge.v1.Params.prototype.getSignAgainCoolDownDuration = function () {
  return /** @type{?proto.google.protobuf.Duration} */ (
    jspb.Message.getWrapperField(this, google_protobuf_duration_pb.Duration, 2)
  );
};

/**
 * @param {?proto.google.protobuf.Duration|undefined} value
 * @return {!proto.sgn.cbridge.v1.Params} returns this
 */
proto.sgn.cbridge.v1.Params.prototype.setSignAgainCoolDownDuration = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.cbridge.v1.Params} returns this
 */
proto.sgn.cbridge.v1.Params.prototype.clearSignAgainCoolDownDuration = function () {
  return this.setSignAgainCoolDownDuration(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.cbridge.v1.Params.prototype.hasSignAgainCoolDownDuration = function () {
  return jspb.Message.getField(this, 2) != null;
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.cbridge.v1.CbrConfig.repeatedFields_ = [5, 2, 3, 6, 7];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.CbrConfig.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.CbrConfig.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.CbrConfig} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.CbrConfig.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        lpFeePerc: jspb.Message.getFieldWithDefault(msg, 1, 0),
        pickLpSize: jspb.Message.getFieldWithDefault(msg, 4, 0),
        relayGasCostList: jspb.Message.toObjectList(
          msg.getRelayGasCostList(),
          proto.sgn.cbridge.v1.RelayGasCostParam.toObject,
          includeInstance,
        ),
        assetsList: jspb.Message.toObjectList(
          msg.getAssetsList(),
          proto.sgn.cbridge.v1.ChainAsset.toObject,
          includeInstance,
        ),
        chainPairsList: jspb.Message.toObjectList(
          msg.getChainPairsList(),
          proto.sgn.cbridge.v1.ChainPair.toObject,
          includeInstance,
        ),
        cbrContractsList: jspb.Message.toObjectList(
          msg.getCbrContractsList(),
          sgn_common_v1_common_pb.ContractInfo.toObject,
          includeInstance,
        ),
        overrideList: jspb.Message.toObjectList(
          msg.getOverrideList(),
          proto.sgn.cbridge.v1.PerChainPairAssetOverride.toObject,
          includeInstance,
        ),
        maxGainPerc: jspb.Message.getFieldWithDefault(msg, 8, 0),
        updateGasCost: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.CbrConfig}
 */
proto.sgn.cbridge.v1.CbrConfig.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.CbrConfig();
  return proto.sgn.cbridge.v1.CbrConfig.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.CbrConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.CbrConfig}
 */
proto.sgn.cbridge.v1.CbrConfig.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setLpFeePerc(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPickLpSize(value);
        break;
      case 5:
        var value = new proto.sgn.cbridge.v1.RelayGasCostParam();
        reader.readMessage(value, proto.sgn.cbridge.v1.RelayGasCostParam.deserializeBinaryFromReader);
        msg.addRelayGasCost(value);
        break;
      case 2:
        var value = new proto.sgn.cbridge.v1.ChainAsset();
        reader.readMessage(value, proto.sgn.cbridge.v1.ChainAsset.deserializeBinaryFromReader);
        msg.addAssets(value);
        break;
      case 3:
        var value = new proto.sgn.cbridge.v1.ChainPair();
        reader.readMessage(value, proto.sgn.cbridge.v1.ChainPair.deserializeBinaryFromReader);
        msg.addChainPairs(value);
        break;
      case 6:
        var value = new sgn_common_v1_common_pb.ContractInfo();
        reader.readMessage(value, sgn_common_v1_common_pb.ContractInfo.deserializeBinaryFromReader);
        msg.addCbrContracts(value);
        break;
      case 7:
        var value = new proto.sgn.cbridge.v1.PerChainPairAssetOverride();
        reader.readMessage(value, proto.sgn.cbridge.v1.PerChainPairAssetOverride.deserializeBinaryFromReader);
        msg.addOverride(value);
        break;
      case 8:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setMaxGainPerc(value);
        break;
      case 9:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setUpdateGasCost(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.CbrConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.CbrConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.CbrConfig.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getLpFeePerc();
  if (f !== 0) {
    writer.writeUint32(1, f);
  }
  f = message.getPickLpSize();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getRelayGasCostList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(5, f, proto.sgn.cbridge.v1.RelayGasCostParam.serializeBinaryToWriter);
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.sgn.cbridge.v1.ChainAsset.serializeBinaryToWriter);
  }
  f = message.getChainPairsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.sgn.cbridge.v1.ChainPair.serializeBinaryToWriter);
  }
  f = message.getCbrContractsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(6, f, sgn_common_v1_common_pb.ContractInfo.serializeBinaryToWriter);
  }
  f = message.getOverrideList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(7, f, proto.sgn.cbridge.v1.PerChainPairAssetOverride.serializeBinaryToWriter);
  }
  f = message.getMaxGainPerc();
  if (f !== 0) {
    writer.writeUint32(8, f);
  }
  f = message.getUpdateGasCost();
  if (f) {
    writer.writeBool(9, f);
  }
};

/**
 * optional uint32 lp_fee_perc = 1;
 * @return {number}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.getLpFeePerc = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.setLpFeePerc = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional uint32 pick_lp_size = 4;
 * @return {number}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.getPickLpSize = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.setPickLpSize = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * repeated RelayGasCostParam relay_gas_cost = 5;
 * @return {!Array<!proto.sgn.cbridge.v1.RelayGasCostParam>}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.getRelayGasCostList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.RelayGasCostParam>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.RelayGasCostParam, 5)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.RelayGasCostParam>} value
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.setRelayGasCostList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.RelayGasCostParam=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.RelayGasCostParam}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.addRelayGasCost = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sgn.cbridge.v1.RelayGasCostParam, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.clearRelayGasCostList = function () {
  return this.setRelayGasCostList([]);
};

/**
 * repeated ChainAsset assets = 2;
 * @return {!Array<!proto.sgn.cbridge.v1.ChainAsset>}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.getAssetsList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.ChainAsset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.ChainAsset, 2)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.ChainAsset>} value
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.setAssetsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.ChainAsset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.ChainAsset}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.addAssets = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sgn.cbridge.v1.ChainAsset, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.clearAssetsList = function () {
  return this.setAssetsList([]);
};

/**
 * repeated ChainPair chain_pairs = 3;
 * @return {!Array<!proto.sgn.cbridge.v1.ChainPair>}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.getChainPairsList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.ChainPair>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.ChainPair, 3)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.ChainPair>} value
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.setChainPairsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.ChainPair=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.ChainPair}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.addChainPairs = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sgn.cbridge.v1.ChainPair, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.clearChainPairsList = function () {
  return this.setChainPairsList([]);
};

/**
 * repeated sgn.common.v1.ContractInfo cbr_contracts = 6;
 * @return {!Array<!proto.sgn.common.v1.ContractInfo>}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.getCbrContractsList = function () {
  return /** @type{!Array<!proto.sgn.common.v1.ContractInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, sgn_common_v1_common_pb.ContractInfo, 6)
  );
};

/**
 * @param {!Array<!proto.sgn.common.v1.ContractInfo>} value
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.setCbrContractsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.sgn.common.v1.ContractInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.common.v1.ContractInfo}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.addCbrContracts = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.sgn.common.v1.ContractInfo, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.clearCbrContractsList = function () {
  return this.setCbrContractsList([]);
};

/**
 * repeated PerChainPairAssetOverride override = 7;
 * @return {!Array<!proto.sgn.cbridge.v1.PerChainPairAssetOverride>}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.getOverrideList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.PerChainPairAssetOverride>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.PerChainPairAssetOverride, 7)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.PerChainPairAssetOverride>} value
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.setOverrideList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.PerChainPairAssetOverride=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.PerChainPairAssetOverride}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.addOverride = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(
    this,
    7,
    opt_value,
    proto.sgn.cbridge.v1.PerChainPairAssetOverride,
    opt_index,
  );
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.clearOverrideList = function () {
  return this.setOverrideList([]);
};

/**
 * optional uint32 max_gain_perc = 8;
 * @return {number}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.getMaxGainPerc = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.setMaxGainPerc = function (value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};

/**
 * optional bool update_gas_cost = 9;
 * @return {boolean}
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.getUpdateGasCost = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};

/**
 * @param {boolean} value
 * @return {!proto.sgn.cbridge.v1.CbrConfig} returns this
 */
proto.sgn.cbridge.v1.CbrConfig.prototype.setUpdateGasCost = function (value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.cbridge.v1.CbrPrice.repeatedFields_ = [5, 6];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.CbrPrice.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.CbrPrice.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.CbrPrice} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.CbrPrice.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        updateEpoch: jspb.Message.getFieldWithDefault(msg, 1, 0),
        assetPriceList: jspb.Message.toObjectList(
          msg.getAssetPriceList(),
          proto.sgn.cbridge.v1.AssetPrice.toObject,
          includeInstance,
        ),
        gasPriceList: jspb.Message.toObjectList(
          msg.getGasPriceList(),
          proto.sgn.cbridge.v1.GasPrice.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.CbrPrice}
 */
proto.sgn.cbridge.v1.CbrPrice.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.CbrPrice();
  return proto.sgn.cbridge.v1.CbrPrice.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.CbrPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.CbrPrice}
 */
proto.sgn.cbridge.v1.CbrPrice.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setUpdateEpoch(value);
        break;
      case 5:
        var value = new proto.sgn.cbridge.v1.AssetPrice();
        reader.readMessage(value, proto.sgn.cbridge.v1.AssetPrice.deserializeBinaryFromReader);
        msg.addAssetPrice(value);
        break;
      case 6:
        var value = new proto.sgn.cbridge.v1.GasPrice();
        reader.readMessage(value, proto.sgn.cbridge.v1.GasPrice.deserializeBinaryFromReader);
        msg.addGasPrice(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.CbrPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.CbrPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.CbrPrice.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getUpdateEpoch();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getAssetPriceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(5, f, proto.sgn.cbridge.v1.AssetPrice.serializeBinaryToWriter);
  }
  f = message.getGasPriceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(6, f, proto.sgn.cbridge.v1.GasPrice.serializeBinaryToWriter);
  }
};

/**
 * optional uint64 update_epoch = 1;
 * @return {number}
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.getUpdateEpoch = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.CbrPrice} returns this
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.setUpdateEpoch = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * repeated AssetPrice asset_price = 5;
 * @return {!Array<!proto.sgn.cbridge.v1.AssetPrice>}
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.getAssetPriceList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.AssetPrice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.AssetPrice, 5)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.AssetPrice>} value
 * @return {!proto.sgn.cbridge.v1.CbrPrice} returns this
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.setAssetPriceList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.AssetPrice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.AssetPrice}
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.addAssetPrice = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.sgn.cbridge.v1.AssetPrice, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.CbrPrice} returns this
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.clearAssetPriceList = function () {
  return this.setAssetPriceList([]);
};

/**
 * repeated GasPrice gas_price = 6;
 * @return {!Array<!proto.sgn.cbridge.v1.GasPrice>}
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.getGasPriceList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.GasPrice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.GasPrice, 6)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.GasPrice>} value
 * @return {!proto.sgn.cbridge.v1.CbrPrice} returns this
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.setGasPriceList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.GasPrice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.GasPrice}
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.addGasPrice = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.sgn.cbridge.v1.GasPrice, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.CbrPrice} returns this
 */
proto.sgn.cbridge.v1.CbrPrice.prototype.clearGasPriceList = function () {
  return this.setGasPriceList([]);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.RelayGasCostParam.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.RelayGasCostParam.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.RelayGasCostParam} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.RelayGasCostParam.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        costBase: jspb.Message.getFieldWithDefault(msg, 2, 0),
        perValidator: jspb.Message.getFieldWithDefault(msg, 3, 0),
        perSig: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.RelayGasCostParam}
 */
proto.sgn.cbridge.v1.RelayGasCostParam.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.RelayGasCostParam();
  return proto.sgn.cbridge.v1.RelayGasCostParam.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.RelayGasCostParam} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.RelayGasCostParam}
 */
proto.sgn.cbridge.v1.RelayGasCostParam.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setChainId(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setCostBase(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPerValidator(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPerSig(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.RelayGasCostParam.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.RelayGasCostParam.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.RelayGasCostParam} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.RelayGasCostParam.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getCostBase();
  if (f !== 0) {
    writer.writeUint32(2, f);
  }
  f = message.getPerValidator();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getPerSig();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
};

/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.cbridge.v1.RelayGasCostParam.prototype.getChainId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.RelayGasCostParam} returns this
 */
proto.sgn.cbridge.v1.RelayGasCostParam.prototype.setChainId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional uint32 cost_base = 2;
 * @return {number}
 */
proto.sgn.cbridge.v1.RelayGasCostParam.prototype.getCostBase = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.RelayGasCostParam} returns this
 */
proto.sgn.cbridge.v1.RelayGasCostParam.prototype.setCostBase = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional uint32 per_validator = 3;
 * @return {number}
 */
proto.sgn.cbridge.v1.RelayGasCostParam.prototype.getPerValidator = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.RelayGasCostParam} returns this
 */
proto.sgn.cbridge.v1.RelayGasCostParam.prototype.setPerValidator = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 per_sig = 4;
 * @return {number}
 */
proto.sgn.cbridge.v1.RelayGasCostParam.prototype.getPerSig = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.RelayGasCostParam} returns this
 */
proto.sgn.cbridge.v1.RelayGasCostParam.prototype.setPerSig = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.cbridge.v1.AssetPrice.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.AssetPrice.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.AssetPrice.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.AssetPrice} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.AssetPrice.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
        chainIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
        price: jspb.Message.getFieldWithDefault(msg, 3, 0),
        extraPower10: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.AssetPrice}
 */
proto.sgn.cbridge.v1.AssetPrice.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.AssetPrice();
  return proto.sgn.cbridge.v1.AssetPrice.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.AssetPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.AssetPrice}
 */
proto.sgn.cbridge.v1.AssetPrice.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSymbol(value);
        break;
      case 2:
        var values = /** @type {!Array<number>} */ (
          reader.isDelimited() ? reader.readPackedUint64() : [reader.readUint64()]
        );
        for (var i = 0; i < values.length; i++) {
          msg.addChainIds(values[i]);
        }
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setPrice(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setExtraPower10(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.AssetPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.AssetPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.AssetPrice.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getChainIdsList();
  if (f.length > 0) {
    writer.writePackedUint64(2, f);
  }
  f = message.getPrice();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getExtraPower10();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
};

/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.getSymbol = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.AssetPrice} returns this
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.setSymbol = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * repeated uint64 chain_ids = 2;
 * @return {!Array<number>}
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.getChainIdsList = function () {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};

/**
 * @param {!Array<number>} value
 * @return {!proto.sgn.cbridge.v1.AssetPrice} returns this
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.setChainIdsList = function (value) {
  return jspb.Message.setField(this, 2, value || []);
};

/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.AssetPrice} returns this
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.addChainIds = function (value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.AssetPrice} returns this
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.clearChainIdsList = function () {
  return this.setChainIdsList([]);
};

/**
 * optional uint32 price = 3;
 * @return {number}
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.getPrice = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.AssetPrice} returns this
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.setPrice = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 extra_power10 = 4;
 * @return {number}
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.getExtraPower10 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.AssetPrice} returns this
 */
proto.sgn.cbridge.v1.AssetPrice.prototype.setExtraPower10 = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.GasPrice.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.GasPrice.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.GasPrice} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.GasPrice.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        price: jspb.Message.getFieldWithDefault(msg, 2, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.GasPrice}
 */
proto.sgn.cbridge.v1.GasPrice.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.GasPrice();
  return proto.sgn.cbridge.v1.GasPrice.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.GasPrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.GasPrice}
 */
proto.sgn.cbridge.v1.GasPrice.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setChainId(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setPrice(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.GasPrice.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.GasPrice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.GasPrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.GasPrice.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
};

/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.cbridge.v1.GasPrice.prototype.getChainId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.GasPrice} returns this
 */
proto.sgn.cbridge.v1.GasPrice.prototype.setChainId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string price = 2;
 * @return {string}
 */
proto.sgn.cbridge.v1.GasPrice.prototype.getPrice = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.GasPrice} returns this
 */
proto.sgn.cbridge.v1.GasPrice.prototype.setPrice = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.ChainAsset.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.ChainAsset.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.ChainAsset} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.ChainAsset.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
        chainId: jspb.Message.getFieldWithDefault(msg, 2, 0),
        addr: jspb.Message.getFieldWithDefault(msg, 3, ""),
        decimal: jspb.Message.getFieldWithDefault(msg, 4, 0),
        maxFeeAmount: jspb.Message.getFieldWithDefault(msg, 5, ""),
        xferDisabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
        maxOutAmt: jspb.Message.getFieldWithDefault(msg, 7, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.ChainAsset}
 */
proto.sgn.cbridge.v1.ChainAsset.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.ChainAsset();
  return proto.sgn.cbridge.v1.ChainAsset.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.ChainAsset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.ChainAsset}
 */
proto.sgn.cbridge.v1.ChainAsset.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSymbol(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setChainId(value);
        break;
      case 3:
        var value = /** @type {string} */ (reader.readString());
        msg.setAddr(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setDecimal(value);
        break;
      case 5:
        var value = /** @type {string} */ (reader.readString());
        msg.setMaxFeeAmount(value);
        break;
      case 6:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setXferDisabled(value);
        break;
      case 7:
        var value = /** @type {string} */ (reader.readString());
        msg.setMaxOutAmt(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.ChainAsset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.ChainAsset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.ChainAsset.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(2, f);
  }
  f = message.getAddr();
  if (f.length > 0) {
    writer.writeString(3, f);
  }
  f = message.getDecimal();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getMaxFeeAmount();
  if (f.length > 0) {
    writer.writeString(5, f);
  }
  f = message.getXferDisabled();
  if (f) {
    writer.writeBool(6, f);
  }
  f = message.getMaxOutAmt();
  if (f.length > 0) {
    writer.writeString(7, f);
  }
};

/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.getSymbol = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.ChainAsset} returns this
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.setSymbol = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional uint64 chain_id = 2;
 * @return {number}
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.getChainId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.ChainAsset} returns this
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.setChainId = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional string addr = 3;
 * @return {string}
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.getAddr = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.ChainAsset} returns this
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.setAddr = function (value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};

/**
 * optional uint32 decimal = 4;
 * @return {number}
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.getDecimal = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.ChainAsset} returns this
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.setDecimal = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional string max_fee_amount = 5;
 * @return {string}
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.getMaxFeeAmount = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.ChainAsset} returns this
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.setMaxFeeAmount = function (value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};

/**
 * optional bool xfer_disabled = 6;
 * @return {boolean}
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.getXferDisabled = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};

/**
 * @param {boolean} value
 * @return {!proto.sgn.cbridge.v1.ChainAsset} returns this
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.setXferDisabled = function (value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};

/**
 * optional string max_out_amt = 7;
 * @return {string}
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.getMaxOutAmt = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.ChainAsset} returns this
 */
proto.sgn.cbridge.v1.ChainAsset.prototype.setMaxOutAmt = function (value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.ChainPair.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.ChainPair.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.ChainPair} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.ChainPair.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        chid1: jspb.Message.getFieldWithDefault(msg, 1, 0),
        chid2: jspb.Message.getFieldWithDefault(msg, 2, 0),
        weight1: jspb.Message.getFieldWithDefault(msg, 3, 0),
        fee1to2: jspb.Message.getFieldWithDefault(msg, 4, 0),
        fee2to1: jspb.Message.getFieldWithDefault(msg, 5, 0),
        constA: jspb.Message.getFieldWithDefault(msg, 6, 0),
        noCurve: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.ChainPair}
 */
proto.sgn.cbridge.v1.ChainPair.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.ChainPair();
  return proto.sgn.cbridge.v1.ChainPair.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.ChainPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.ChainPair}
 */
proto.sgn.cbridge.v1.ChainPair.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setChid1(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setChid2(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setWeight1(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setFee1to2(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setFee2to1(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint32());
        msg.setConstA(value);
        break;
      case 7:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setNoCurve(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.ChainPair.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.ChainPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.ChainPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.ChainPair.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChid1();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getChid2();
  if (f !== 0) {
    writer.writeUint64(2, f);
  }
  f = message.getWeight1();
  if (f !== 0) {
    writer.writeUint32(3, f);
  }
  f = message.getFee1to2();
  if (f !== 0) {
    writer.writeUint32(4, f);
  }
  f = message.getFee2to1();
  if (f !== 0) {
    writer.writeUint32(5, f);
  }
  f = message.getConstA();
  if (f !== 0) {
    writer.writeUint32(6, f);
  }
  f = message.getNoCurve();
  if (f) {
    writer.writeBool(7, f);
  }
};

/**
 * optional uint64 chid1 = 1;
 * @return {number}
 */
proto.sgn.cbridge.v1.ChainPair.prototype.getChid1 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.ChainPair} returns this
 */
proto.sgn.cbridge.v1.ChainPair.prototype.setChid1 = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional uint64 chid2 = 2;
 * @return {number}
 */
proto.sgn.cbridge.v1.ChainPair.prototype.getChid2 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.ChainPair} returns this
 */
proto.sgn.cbridge.v1.ChainPair.prototype.setChid2 = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional uint32 weight1 = 3;
 * @return {number}
 */
proto.sgn.cbridge.v1.ChainPair.prototype.getWeight1 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.ChainPair} returns this
 */
proto.sgn.cbridge.v1.ChainPair.prototype.setWeight1 = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint32 fee1to2 = 4;
 * @return {number}
 */
proto.sgn.cbridge.v1.ChainPair.prototype.getFee1to2 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.ChainPair} returns this
 */
proto.sgn.cbridge.v1.ChainPair.prototype.setFee1to2 = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional uint32 fee2to1 = 5;
 * @return {number}
 */
proto.sgn.cbridge.v1.ChainPair.prototype.getFee2to1 = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.ChainPair} returns this
 */
proto.sgn.cbridge.v1.ChainPair.prototype.setFee2to1 = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional uint32 const_a = 6;
 * @return {number}
 */
proto.sgn.cbridge.v1.ChainPair.prototype.getConstA = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.ChainPair} returns this
 */
proto.sgn.cbridge.v1.ChainPair.prototype.setConstA = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional bool no_curve = 7;
 * @return {boolean}
 */
proto.sgn.cbridge.v1.ChainPair.prototype.getNoCurve = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};

/**
 * @param {boolean} value
 * @return {!proto.sgn.cbridge.v1.ChainPair} returns this
 */
proto.sgn.cbridge.v1.ChainPair.prototype.setNoCurve = function (value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.PerChainPairAssetOverride.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.PerChainPairAssetOverride.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.PerChainPairAssetOverride} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.PerChainPairAssetOverride.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
        chpair: (f = msg.getChpair()) && proto.sgn.cbridge.v1.ChainPair.toObject(includeInstance, f),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.PerChainPairAssetOverride}
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.PerChainPairAssetOverride();
  return proto.sgn.cbridge.v1.PerChainPairAssetOverride.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.PerChainPairAssetOverride} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.PerChainPairAssetOverride}
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setSymbol(value);
        break;
      case 2:
        var value = new proto.sgn.cbridge.v1.ChainPair();
        reader.readMessage(value, proto.sgn.cbridge.v1.ChainPair.deserializeBinaryFromReader);
        msg.setChpair(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.PerChainPairAssetOverride.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.PerChainPairAssetOverride} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getChpair();
  if (f != null) {
    writer.writeMessage(2, f, proto.sgn.cbridge.v1.ChainPair.serializeBinaryToWriter);
  }
};

/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride.prototype.getSymbol = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.PerChainPairAssetOverride} returns this
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride.prototype.setSymbol = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional ChainPair chpair = 2;
 * @return {?proto.sgn.cbridge.v1.ChainPair}
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride.prototype.getChpair = function () {
  return /** @type{?proto.sgn.cbridge.v1.ChainPair} */ (
    jspb.Message.getWrapperField(this, proto.sgn.cbridge.v1.ChainPair, 2)
  );
};

/**
 * @param {?proto.sgn.cbridge.v1.ChainPair|undefined} value
 * @return {!proto.sgn.cbridge.v1.PerChainPairAssetOverride} returns this
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride.prototype.setChpair = function (value) {
  return jspb.Message.setWrapperField(this, 2, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.cbridge.v1.PerChainPairAssetOverride} returns this
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride.prototype.clearChpair = function () {
  return this.setChpair(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.cbridge.v1.PerChainPairAssetOverride.prototype.hasChpair = function () {
  return jspb.Message.getField(this, 2) != null;
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.CbrProposal.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.CbrProposal.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.CbrProposal} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.CbrProposal.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        title: jspb.Message.getFieldWithDefault(msg, 1, ""),
        description: jspb.Message.getFieldWithDefault(msg, 2, ""),
        cbrConfig: (f = msg.getCbrConfig()) && proto.sgn.cbridge.v1.CbrConfig.toObject(includeInstance, f),
        deposit: jspb.Message.getFieldWithDefault(msg, 4, ""),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.CbrProposal}
 */
proto.sgn.cbridge.v1.CbrProposal.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.CbrProposal();
  return proto.sgn.cbridge.v1.CbrProposal.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.CbrProposal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.CbrProposal}
 */
proto.sgn.cbridge.v1.CbrProposal.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {string} */ (reader.readString());
        msg.setTitle(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setDescription(value);
        break;
      case 3:
        var value = new proto.sgn.cbridge.v1.CbrConfig();
        reader.readMessage(value, proto.sgn.cbridge.v1.CbrConfig.deserializeBinaryFromReader);
        msg.setCbrConfig(value);
        break;
      case 4:
        var value = /** @type {string} */ (reader.readString());
        msg.setDeposit(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.CbrProposal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.CbrProposal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.CbrProposal.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(1, f);
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getCbrConfig();
  if (f != null) {
    writer.writeMessage(3, f, proto.sgn.cbridge.v1.CbrConfig.serializeBinaryToWriter);
  }
  f = message.getDeposit();
  if (f.length > 0) {
    writer.writeString(4, f);
  }
};

/**
 * optional string title = 1;
 * @return {string}
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.getTitle = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.CbrProposal} returns this
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.setTitle = function (value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};

/**
 * optional string description = 2;
 * @return {string}
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.getDescription = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.CbrProposal} returns this
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.setDescription = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional CbrConfig cbr_config = 3;
 * @return {?proto.sgn.cbridge.v1.CbrConfig}
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.getCbrConfig = function () {
  return /** @type{?proto.sgn.cbridge.v1.CbrConfig} */ (
    jspb.Message.getWrapperField(this, proto.sgn.cbridge.v1.CbrConfig, 3)
  );
};

/**
 * @param {?proto.sgn.cbridge.v1.CbrConfig|undefined} value
 * @return {!proto.sgn.cbridge.v1.CbrProposal} returns this
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.setCbrConfig = function (value) {
  return jspb.Message.setWrapperField(this, 3, value);
};

/**
 * Clears the message field making it undefined.
 * @return {!proto.sgn.cbridge.v1.CbrProposal} returns this
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.clearCbrConfig = function () {
  return this.setCbrConfig(undefined);
};

/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.hasCbrConfig = function () {
  return jspb.Message.getField(this, 3) != null;
};

/**
 * optional string deposit = 4;
 * @return {string}
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.getDeposit = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.CbrProposal} returns this
 */
proto.sgn.cbridge.v1.CbrProposal.prototype.setDeposit = function (value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.OnChainEvent.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.OnChainEvent.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.OnChainEvent} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.OnChainEvent.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        chainid: jspb.Message.getFieldWithDefault(msg, 1, 0),
        evtype: jspb.Message.getFieldWithDefault(msg, 2, ""),
        elog: msg.getElog_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.OnChainEvent}
 */
proto.sgn.cbridge.v1.OnChainEvent.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.OnChainEvent();
  return proto.sgn.cbridge.v1.OnChainEvent.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.OnChainEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.OnChainEvent}
 */
proto.sgn.cbridge.v1.OnChainEvent.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setChainid(value);
        break;
      case 2:
        var value = /** @type {string} */ (reader.readString());
        msg.setEvtype(value);
        break;
      case 3:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setElog(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.OnChainEvent.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.OnChainEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.OnChainEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.OnChainEvent.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChainid();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getEvtype();
  if (f.length > 0) {
    writer.writeString(2, f);
  }
  f = message.getElog_asU8();
  if (f.length > 0) {
    writer.writeBytes(3, f);
  }
};

/**
 * optional uint64 chainid = 1;
 * @return {number}
 */
proto.sgn.cbridge.v1.OnChainEvent.prototype.getChainid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.OnChainEvent} returns this
 */
proto.sgn.cbridge.v1.OnChainEvent.prototype.setChainid = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional string evtype = 2;
 * @return {string}
 */
proto.sgn.cbridge.v1.OnChainEvent.prototype.getEvtype = function () {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * @param {string} value
 * @return {!proto.sgn.cbridge.v1.OnChainEvent} returns this
 */
proto.sgn.cbridge.v1.OnChainEvent.prototype.setEvtype = function (value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};

/**
 * optional bytes elog = 3;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.OnChainEvent.prototype.getElog = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * optional bytes elog = 3;
 * This is a type-conversion wrapper around `getElog()`
 * @return {string}
 */
proto.sgn.cbridge.v1.OnChainEvent.prototype.getElog_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getElog()));
};

/**
 * optional bytes elog = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getElog()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.OnChainEvent.prototype.getElog_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getElog()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.OnChainEvent} returns this
 */
proto.sgn.cbridge.v1.OnChainEvent.prototype.setElog = function (value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.RelayOnChain.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.RelayOnChain.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.RelayOnChain} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.RelayOnChain.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sender: msg.getSender_asB64(),
        receiver: msg.getReceiver_asB64(),
        token: msg.getToken_asB64(),
        amount: msg.getAmount_asB64(),
        srcChainId: jspb.Message.getFieldWithDefault(msg, 5, 0),
        dstChainId: jspb.Message.getFieldWithDefault(msg, 6, 0),
        srcTransferId: msg.getSrcTransferId_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.RelayOnChain}
 */
proto.sgn.cbridge.v1.RelayOnChain.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.RelayOnChain();
  return proto.sgn.cbridge.v1.RelayOnChain.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.RelayOnChain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.RelayOnChain}
 */
proto.sgn.cbridge.v1.RelayOnChain.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSender(value);
        break;
      case 2:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setReceiver(value);
        break;
      case 3:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setToken(value);
        break;
      case 4:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setAmount(value);
        break;
      case 5:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setSrcChainId(value);
        break;
      case 6:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setDstChainId(value);
        break;
      case 7:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSrcTransferId(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.RelayOnChain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.RelayOnChain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.RelayOnChain.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSender_asU8();
  if (f.length > 0) {
    writer.writeBytes(1, f);
  }
  f = message.getReceiver_asU8();
  if (f.length > 0) {
    writer.writeBytes(2, f);
  }
  f = message.getToken_asU8();
  if (f.length > 0) {
    writer.writeBytes(3, f);
  }
  f = message.getAmount_asU8();
  if (f.length > 0) {
    writer.writeBytes(4, f);
  }
  f = message.getSrcChainId();
  if (f !== 0) {
    writer.writeUint64(5, f);
  }
  f = message.getDstChainId();
  if (f !== 0) {
    writer.writeUint64(6, f);
  }
  f = message.getSrcTransferId_asU8();
  if (f.length > 0) {
    writer.writeBytes(7, f);
  }
};

/**
 * optional bytes sender = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getSender = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * optional bytes sender = 1;
 * This is a type-conversion wrapper around `getSender()`
 * @return {string}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getSender_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getSender()));
};

/**
 * optional bytes sender = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSender()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getSender_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getSender()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.RelayOnChain} returns this
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.setSender = function (value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes receiver = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getReceiver = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * optional bytes receiver = 2;
 * This is a type-conversion wrapper around `getReceiver()`
 * @return {string}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getReceiver_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getReceiver()));
};

/**
 * optional bytes receiver = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReceiver()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getReceiver_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getReceiver()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.RelayOnChain} returns this
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.setReceiver = function (value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * optional bytes token = 3;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getToken = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * optional bytes token = 3;
 * This is a type-conversion wrapper around `getToken()`
 * @return {string}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getToken_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getToken()));
};

/**
 * optional bytes token = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getToken()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getToken_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getToken()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.RelayOnChain} returns this
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.setToken = function (value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};

/**
 * optional bytes amount = 4;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getAmount = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * optional bytes amount = 4;
 * This is a type-conversion wrapper around `getAmount()`
 * @return {string}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getAmount_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getAmount()));
};

/**
 * optional bytes amount = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAmount()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getAmount_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getAmount()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.RelayOnChain} returns this
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.setAmount = function (value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};

/**
 * optional uint64 src_chain_id = 5;
 * @return {number}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getSrcChainId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.RelayOnChain} returns this
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.setSrcChainId = function (value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};

/**
 * optional uint64 dst_chain_id = 6;
 * @return {number}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getDstChainId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.RelayOnChain} returns this
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.setDstChainId = function (value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};

/**
 * optional bytes src_transfer_id = 7;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getSrcTransferId = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};

/**
 * optional bytes src_transfer_id = 7;
 * This is a type-conversion wrapper around `getSrcTransferId()`
 * @return {string}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getSrcTransferId_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getSrcTransferId()));
};

/**
 * optional bytes src_transfer_id = 7;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSrcTransferId()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.getSrcTransferId_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getSrcTransferId()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.RelayOnChain} returns this
 */
proto.sgn.cbridge.v1.RelayOnChain.prototype.setSrcTransferId = function (value) {
  return jspb.Message.setProto3BytesField(this, 7, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.WithdrawOnchain.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.WithdrawOnchain.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.WithdrawOnchain} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.WithdrawOnchain.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        chainid: jspb.Message.getFieldWithDefault(msg, 1, 0),
        seqnum: jspb.Message.getFieldWithDefault(msg, 2, 0),
        receiver: msg.getReceiver_asB64(),
        token: msg.getToken_asB64(),
        amount: msg.getAmount_asB64(),
        refid: msg.getRefid_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.WithdrawOnchain}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.WithdrawOnchain();
  return proto.sgn.cbridge.v1.WithdrawOnchain.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.WithdrawOnchain} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.WithdrawOnchain}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setChainid(value);
        break;
      case 2:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setSeqnum(value);
        break;
      case 3:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setReceiver(value);
        break;
      case 4:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setToken(value);
        break;
      case 5:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setAmount(value);
        break;
      case 6:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setRefid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.WithdrawOnchain.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.WithdrawOnchain} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.WithdrawOnchain.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChainid();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getSeqnum();
  if (f !== 0) {
    writer.writeUint64(2, f);
  }
  f = message.getReceiver_asU8();
  if (f.length > 0) {
    writer.writeBytes(3, f);
  }
  f = message.getToken_asU8();
  if (f.length > 0) {
    writer.writeBytes(4, f);
  }
  f = message.getAmount_asU8();
  if (f.length > 0) {
    writer.writeBytes(5, f);
  }
  f = message.getRefid_asU8();
  if (f.length > 0) {
    writer.writeBytes(6, f);
  }
};

/**
 * optional uint64 chainid = 1;
 * @return {number}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getChainid = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.WithdrawOnchain} returns this
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.setChainid = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * optional uint64 seqnum = 2;
 * @return {number}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getSeqnum = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.WithdrawOnchain} returns this
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.setSeqnum = function (value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};

/**
 * optional bytes receiver = 3;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getReceiver = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};

/**
 * optional bytes receiver = 3;
 * This is a type-conversion wrapper around `getReceiver()`
 * @return {string}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getReceiver_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getReceiver()));
};

/**
 * optional bytes receiver = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getReceiver()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getReceiver_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getReceiver()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.WithdrawOnchain} returns this
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.setReceiver = function (value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};

/**
 * optional bytes token = 4;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getToken = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * optional bytes token = 4;
 * This is a type-conversion wrapper around `getToken()`
 * @return {string}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getToken_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getToken()));
};

/**
 * optional bytes token = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getToken()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getToken_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getToken()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.WithdrawOnchain} returns this
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.setToken = function (value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};

/**
 * optional bytes amount = 5;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getAmount = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * optional bytes amount = 5;
 * This is a type-conversion wrapper around `getAmount()`
 * @return {string}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getAmount_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getAmount()));
};

/**
 * optional bytes amount = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAmount()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getAmount_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getAmount()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.WithdrawOnchain} returns this
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.setAmount = function (value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};

/**
 * optional bytes refid = 6;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getRefid = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};

/**
 * optional bytes refid = 6;
 * This is a type-conversion wrapper around `getRefid()`
 * @return {string}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getRefid_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getRefid()));
};

/**
 * optional bytes refid = 6;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRefid()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.getRefid_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getRefid()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.WithdrawOnchain} returns this
 */
proto.sgn.cbridge.v1.WithdrawOnchain.prototype.setRefid = function (value) {
  return jspb.Message.setProto3BytesField(this, 6, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.cbridge.v1.XferRelay.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.XferRelay.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.XferRelay.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.XferRelay} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.XferRelay.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        relay: msg.getRelay_asB64(),
        sortedSigsList: jspb.Message.toObjectList(
          msg.getSortedSigsList(),
          proto.sgn.cbridge.v1.AddrSig.toObject,
          includeInstance,
        ),
        lastReqTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
        percFee: msg.getPercFee_asB64(),
        baseFee: msg.getBaseFee_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.XferRelay}
 */
proto.sgn.cbridge.v1.XferRelay.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.XferRelay();
  return proto.sgn.cbridge.v1.XferRelay.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.XferRelay} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.XferRelay}
 */
proto.sgn.cbridge.v1.XferRelay.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setRelay(value);
        break;
      case 2:
        var value = new proto.sgn.cbridge.v1.AddrSig();
        reader.readMessage(value, proto.sgn.cbridge.v1.AddrSig.deserializeBinaryFromReader);
        msg.addSortedSigs(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setLastReqTime(value);
        break;
      case 4:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setPercFee(value);
        break;
      case 5:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setBaseFee(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.XferRelay.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.XferRelay} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.XferRelay.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getRelay_asU8();
  if (f.length > 0) {
    writer.writeBytes(1, f);
  }
  f = message.getSortedSigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.sgn.cbridge.v1.AddrSig.serializeBinaryToWriter);
  }
  f = message.getLastReqTime();
  if (f !== 0) {
    writer.writeInt64(3, f);
  }
  f = message.getPercFee_asU8();
  if (f.length > 0) {
    writer.writeBytes(4, f);
  }
  f = message.getBaseFee_asU8();
  if (f.length > 0) {
    writer.writeBytes(5, f);
  }
};

/**
 * optional bytes relay = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getRelay = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * optional bytes relay = 1;
 * This is a type-conversion wrapper around `getRelay()`
 * @return {string}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getRelay_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getRelay()));
};

/**
 * optional bytes relay = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getRelay()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getRelay_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getRelay()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.XferRelay} returns this
 */
proto.sgn.cbridge.v1.XferRelay.prototype.setRelay = function (value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * repeated AddrSig sorted_sigs = 2;
 * @return {!Array<!proto.sgn.cbridge.v1.AddrSig>}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getSortedSigsList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.AddrSig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.AddrSig, 2)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.AddrSig>} value
 * @return {!proto.sgn.cbridge.v1.XferRelay} returns this
 */
proto.sgn.cbridge.v1.XferRelay.prototype.setSortedSigsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.AddrSig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.AddrSig}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.addSortedSigs = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sgn.cbridge.v1.AddrSig, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.XferRelay} returns this
 */
proto.sgn.cbridge.v1.XferRelay.prototype.clearSortedSigsList = function () {
  return this.setSortedSigsList([]);
};

/**
 * optional int64 last_req_time = 3;
 * @return {number}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getLastReqTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.XferRelay} returns this
 */
proto.sgn.cbridge.v1.XferRelay.prototype.setLastReqTime = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional bytes perc_fee = 4;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getPercFee = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};

/**
 * optional bytes perc_fee = 4;
 * This is a type-conversion wrapper around `getPercFee()`
 * @return {string}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getPercFee_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getPercFee()));
};

/**
 * optional bytes perc_fee = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPercFee()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getPercFee_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getPercFee()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.XferRelay} returns this
 */
proto.sgn.cbridge.v1.XferRelay.prototype.setPercFee = function (value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};

/**
 * optional bytes base_fee = 5;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getBaseFee = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * optional bytes base_fee = 5;
 * This is a type-conversion wrapper around `getBaseFee()`
 * @return {string}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getBaseFee_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getBaseFee()));
};

/**
 * optional bytes base_fee = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBaseFee()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.XferRelay.prototype.getBaseFee_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getBaseFee()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.XferRelay} returns this
 */
proto.sgn.cbridge.v1.XferRelay.prototype.setBaseFee = function (value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.cbridge.v1.WithdrawDetail.repeatedFields_ = [2];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.WithdrawDetail.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.WithdrawDetail.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.WithdrawDetail} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.WithdrawDetail.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        wdOnchain: msg.getWdOnchain_asB64(),
        sortedSigsList: jspb.Message.toObjectList(
          msg.getSortedSigsList(),
          proto.sgn.cbridge.v1.AddrSig.toObject,
          includeInstance,
        ),
        completed: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
        lastReqTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
        xferid: msg.getXferid_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.WithdrawDetail}
 */
proto.sgn.cbridge.v1.WithdrawDetail.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.WithdrawDetail();
  return proto.sgn.cbridge.v1.WithdrawDetail.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.WithdrawDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.WithdrawDetail}
 */
proto.sgn.cbridge.v1.WithdrawDetail.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setWdOnchain(value);
        break;
      case 2:
        var value = new proto.sgn.cbridge.v1.AddrSig();
        reader.readMessage(value, proto.sgn.cbridge.v1.AddrSig.deserializeBinaryFromReader);
        msg.addSortedSigs(value);
        break;
      case 3:
        var value = /** @type {boolean} */ (reader.readBool());
        msg.setCompleted(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readInt64());
        msg.setLastReqTime(value);
        break;
      case 5:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setXferid(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.WithdrawDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.WithdrawDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.WithdrawDetail.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getWdOnchain_asU8();
  if (f.length > 0) {
    writer.writeBytes(1, f);
  }
  f = message.getSortedSigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.sgn.cbridge.v1.AddrSig.serializeBinaryToWriter);
  }
  f = message.getCompleted();
  if (f) {
    writer.writeBool(3, f);
  }
  f = message.getLastReqTime();
  if (f !== 0) {
    writer.writeInt64(4, f);
  }
  f = message.getXferid_asU8();
  if (f.length > 0) {
    writer.writeBytes(5, f);
  }
};

/**
 * optional bytes wd_onchain = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.getWdOnchain = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * optional bytes wd_onchain = 1;
 * This is a type-conversion wrapper around `getWdOnchain()`
 * @return {string}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.getWdOnchain_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getWdOnchain()));
};

/**
 * optional bytes wd_onchain = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getWdOnchain()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.getWdOnchain_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getWdOnchain()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.WithdrawDetail} returns this
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.setWdOnchain = function (value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * repeated AddrSig sorted_sigs = 2;
 * @return {!Array<!proto.sgn.cbridge.v1.AddrSig>}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.getSortedSigsList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.AddrSig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.AddrSig, 2)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.AddrSig>} value
 * @return {!proto.sgn.cbridge.v1.WithdrawDetail} returns this
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.setSortedSigsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.AddrSig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.AddrSig}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.addSortedSigs = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sgn.cbridge.v1.AddrSig, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.WithdrawDetail} returns this
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.clearSortedSigsList = function () {
  return this.setSortedSigsList([]);
};

/**
 * optional bool completed = 3;
 * @return {boolean}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.getCompleted = function () {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};

/**
 * @param {boolean} value
 * @return {!proto.sgn.cbridge.v1.WithdrawDetail} returns this
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.setCompleted = function (value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};

/**
 * optional int64 last_req_time = 4;
 * @return {number}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.getLastReqTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.WithdrawDetail} returns this
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.setLastReqTime = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * optional bytes xferId = 5;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.getXferid = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};

/**
 * optional bytes xferId = 5;
 * This is a type-conversion wrapper around `getXferid()`
 * @return {string}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.getXferid_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getXferid()));
};

/**
 * optional bytes xferId = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getXferid()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.getXferid_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getXferid()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.WithdrawDetail} returns this
 */
proto.sgn.cbridge.v1.WithdrawDetail.prototype.setXferid = function (value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.AddrSig.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.AddrSig.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.AddrSig} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.AddrSig.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        addr: msg.getAddr_asB64(),
        sig: msg.getSig_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.AddrSig}
 */
proto.sgn.cbridge.v1.AddrSig.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.AddrSig();
  return proto.sgn.cbridge.v1.AddrSig.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.AddrSig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.AddrSig}
 */
proto.sgn.cbridge.v1.AddrSig.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setAddr(value);
        break;
      case 2:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSig(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.AddrSig.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.AddrSig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.AddrSig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.AddrSig.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAddr_asU8();
  if (f.length > 0) {
    writer.writeBytes(1, f);
  }
  f = message.getSig_asU8();
  if (f.length > 0) {
    writer.writeBytes(2, f);
  }
};

/**
 * optional bytes addr = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.AddrSig.prototype.getAddr = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * optional bytes addr = 1;
 * This is a type-conversion wrapper around `getAddr()`
 * @return {string}
 */
proto.sgn.cbridge.v1.AddrSig.prototype.getAddr_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getAddr()));
};

/**
 * optional bytes addr = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddr()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.AddrSig.prototype.getAddr_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getAddr()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.AddrSig} returns this
 */
proto.sgn.cbridge.v1.AddrSig.prototype.setAddr = function (value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes sig = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.AddrSig.prototype.getSig = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * optional bytes sig = 2;
 * This is a type-conversion wrapper around `getSig()`
 * @return {string}
 */
proto.sgn.cbridge.v1.AddrSig.prototype.getSig_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getSig()));
};

/**
 * optional bytes sig = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSig()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.AddrSig.prototype.getSig_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getSig()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.AddrSig} returns this
 */
proto.sgn.cbridge.v1.AddrSig.prototype.setSig = function (value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.Signer.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.Signer.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.Signer} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.Signer.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        addr: msg.getAddr_asB64(),
        power: msg.getPower_asB64(),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.Signer}
 */
proto.sgn.cbridge.v1.Signer.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.Signer();
  return proto.sgn.cbridge.v1.Signer.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.Signer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.Signer}
 */
proto.sgn.cbridge.v1.Signer.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setAddr(value);
        break;
      case 2:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setPower(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.Signer.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.Signer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.Signer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.Signer.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getAddr_asU8();
  if (f.length > 0) {
    writer.writeBytes(1, f);
  }
  f = message.getPower_asU8();
  if (f.length > 0) {
    writer.writeBytes(2, f);
  }
};

/**
 * optional bytes addr = 1;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.Signer.prototype.getAddr = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};

/**
 * optional bytes addr = 1;
 * This is a type-conversion wrapper around `getAddr()`
 * @return {string}
 */
proto.sgn.cbridge.v1.Signer.prototype.getAddr_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getAddr()));
};

/**
 * optional bytes addr = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddr()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.Signer.prototype.getAddr_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getAddr()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.Signer} returns this
 */
proto.sgn.cbridge.v1.Signer.prototype.setAddr = function (value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};

/**
 * optional bytes power = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.Signer.prototype.getPower = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * optional bytes power = 2;
 * This is a type-conversion wrapper around `getPower()`
 * @return {string}
 */
proto.sgn.cbridge.v1.Signer.prototype.getPower_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getPower()));
};

/**
 * optional bytes power = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPower()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.Signer.prototype.getPower_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getPower()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.Signer} returns this
 */
proto.sgn.cbridge.v1.Signer.prototype.setPower = function (value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.cbridge.v1.ChainSigners.repeatedFields_ = [2, 3];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.ChainSigners.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.ChainSigners.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.ChainSigners} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.ChainSigners.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        chainId: jspb.Message.getFieldWithDefault(msg, 1, 0),
        sortedSignersList: jspb.Message.toObjectList(
          msg.getSortedSignersList(),
          proto.sgn.cbridge.v1.Signer.toObject,
          includeInstance,
        ),
        sortedSigsList: jspb.Message.toObjectList(
          msg.getSortedSigsList(),
          proto.sgn.cbridge.v1.AddrSig.toObject,
          includeInstance,
        ),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.ChainSigners}
 */
proto.sgn.cbridge.v1.ChainSigners.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.ChainSigners();
  return proto.sgn.cbridge.v1.ChainSigners.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.ChainSigners} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.ChainSigners}
 */
proto.sgn.cbridge.v1.ChainSigners.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setChainId(value);
        break;
      case 2:
        var value = new proto.sgn.cbridge.v1.Signer();
        reader.readMessage(value, proto.sgn.cbridge.v1.Signer.deserializeBinaryFromReader);
        msg.addSortedSigners(value);
        break;
      case 3:
        var value = new proto.sgn.cbridge.v1.AddrSig();
        reader.readMessage(value, proto.sgn.cbridge.v1.AddrSig.deserializeBinaryFromReader);
        msg.addSortedSigs(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.ChainSigners.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.ChainSigners} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.ChainSigners.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getChainId();
  if (f !== 0) {
    writer.writeUint64(1, f);
  }
  f = message.getSortedSignersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(2, f, proto.sgn.cbridge.v1.Signer.serializeBinaryToWriter);
  }
  f = message.getSortedSigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(3, f, proto.sgn.cbridge.v1.AddrSig.serializeBinaryToWriter);
  }
};

/**
 * optional uint64 chain_id = 1;
 * @return {number}
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.getChainId = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.ChainSigners} returns this
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.setChainId = function (value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};

/**
 * repeated Signer sorted_signers = 2;
 * @return {!Array<!proto.sgn.cbridge.v1.Signer>}
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.getSortedSignersList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.Signer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.Signer, 2)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.Signer>} value
 * @return {!proto.sgn.cbridge.v1.ChainSigners} returns this
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.setSortedSignersList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.Signer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.Signer}
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.addSortedSigners = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.sgn.cbridge.v1.Signer, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.ChainSigners} returns this
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.clearSortedSignersList = function () {
  return this.setSortedSignersList([]);
};

/**
 * repeated AddrSig sorted_sigs = 3;
 * @return {!Array<!proto.sgn.cbridge.v1.AddrSig>}
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.getSortedSigsList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.AddrSig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.AddrSig, 3)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.AddrSig>} value
 * @return {!proto.sgn.cbridge.v1.ChainSigners} returns this
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.setSortedSigsList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.AddrSig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.AddrSig}
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.addSortedSigs = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.sgn.cbridge.v1.AddrSig, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.ChainSigners} returns this
 */
proto.sgn.cbridge.v1.ChainSigners.prototype.clearSortedSigsList = function () {
  return this.setSortedSigsList([]);
};

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.sgn.cbridge.v1.LatestSigners.repeatedFields_ = [1];

if (jspb.Message.GENERATE_TO_OBJECT) {
  /**
   * Creates an object representation of this proto.
   * Field names that are reserved in JavaScript and will be renamed to pb_name.
   * Optional fields that are not set will be set to undefined.
   * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
   * For the list of reserved names please see:
   *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
   * @param {boolean=} opt_includeInstance Deprecated. whether to include the
   *     JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @return {!Object}
   */
  proto.sgn.cbridge.v1.LatestSigners.prototype.toObject = function (opt_includeInstance) {
    return proto.sgn.cbridge.v1.LatestSigners.toObject(opt_includeInstance, this);
  };

  /**
   * Static version of the {@see toObject} method.
   * @param {boolean|undefined} includeInstance Deprecated. Whether to include
   *     the JSPB instance for transitional soy proto support:
   *     http://goto/soy-param-migration
   * @param {!proto.sgn.cbridge.v1.LatestSigners} msg The msg instance to transform.
   * @return {!Object}
   * @suppress {unusedLocalVariables} f is only used for nested messages
   */
  proto.sgn.cbridge.v1.LatestSigners.toObject = function (includeInstance, msg) {
    var f,
      obj = {
        sortedSignersList: jspb.Message.toObjectList(
          msg.getSortedSignersList(),
          proto.sgn.cbridge.v1.Signer.toObject,
          includeInstance,
        ),
        signersBytes: msg.getSignersBytes_asB64(),
        triggerTime: jspb.Message.getFieldWithDefault(msg, 3, 0),
        lastSignTime: jspb.Message.getFieldWithDefault(msg, 4, 0),
      };

    if (includeInstance) {
      obj.$jspbMessageInstance = msg;
    }
    return obj;
  };
}

/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.sgn.cbridge.v1.LatestSigners}
 */
proto.sgn.cbridge.v1.LatestSigners.deserializeBinary = function (bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.sgn.cbridge.v1.LatestSigners();
  return proto.sgn.cbridge.v1.LatestSigners.deserializeBinaryFromReader(msg, reader);
};

/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.sgn.cbridge.v1.LatestSigners} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.sgn.cbridge.v1.LatestSigners}
 */
proto.sgn.cbridge.v1.LatestSigners.deserializeBinaryFromReader = function (msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
      case 1:
        var value = new proto.sgn.cbridge.v1.Signer();
        reader.readMessage(value, proto.sgn.cbridge.v1.Signer.deserializeBinaryFromReader);
        msg.addSortedSigners(value);
        break;
      case 2:
        var value = /** @type {!Uint8Array} */ (reader.readBytes());
        msg.setSignersBytes(value);
        break;
      case 3:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setTriggerTime(value);
        break;
      case 4:
        var value = /** @type {number} */ (reader.readUint64());
        msg.setLastSignTime(value);
        break;
      default:
        reader.skipField();
        break;
    }
  }
  return msg;
};

/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.serializeBinary = function () {
  var writer = new jspb.BinaryWriter();
  proto.sgn.cbridge.v1.LatestSigners.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};

/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.sgn.cbridge.v1.LatestSigners} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.sgn.cbridge.v1.LatestSigners.serializeBinaryToWriter = function (message, writer) {
  var f = undefined;
  f = message.getSortedSignersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(1, f, proto.sgn.cbridge.v1.Signer.serializeBinaryToWriter);
  }
  f = message.getSignersBytes_asU8();
  if (f.length > 0) {
    writer.writeBytes(2, f);
  }
  f = message.getTriggerTime();
  if (f !== 0) {
    writer.writeUint64(3, f);
  }
  f = message.getLastSignTime();
  if (f !== 0) {
    writer.writeUint64(4, f);
  }
};

/**
 * repeated Signer sorted_signers = 1;
 * @return {!Array<!proto.sgn.cbridge.v1.Signer>}
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.getSortedSignersList = function () {
  return /** @type{!Array<!proto.sgn.cbridge.v1.Signer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.sgn.cbridge.v1.Signer, 1)
  );
};

/**
 * @param {!Array<!proto.sgn.cbridge.v1.Signer>} value
 * @return {!proto.sgn.cbridge.v1.LatestSigners} returns this
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.setSortedSignersList = function (value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};

/**
 * @param {!proto.sgn.cbridge.v1.Signer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.sgn.cbridge.v1.Signer}
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.addSortedSigners = function (opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.sgn.cbridge.v1.Signer, opt_index);
};

/**
 * Clears the list making it empty but non-null.
 * @return {!proto.sgn.cbridge.v1.LatestSigners} returns this
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.clearSortedSignersList = function () {
  return this.setSortedSignersList([]);
};

/**
 * optional bytes signers_bytes = 2;
 * @return {!(string|Uint8Array)}
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.getSignersBytes = function () {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};

/**
 * optional bytes signers_bytes = 2;
 * This is a type-conversion wrapper around `getSignersBytes()`
 * @return {string}
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.getSignersBytes_asB64 = function () {
  return /** @type {string} */ (jspb.Message.bytesAsB64(this.getSignersBytes()));
};

/**
 * optional bytes signers_bytes = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSignersBytes()`
 * @return {!Uint8Array}
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.getSignersBytes_asU8 = function () {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(this.getSignersBytes()));
};

/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.sgn.cbridge.v1.LatestSigners} returns this
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.setSignersBytes = function (value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};

/**
 * optional uint64 trigger_time = 3;
 * @return {number}
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.getTriggerTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.LatestSigners} returns this
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.setTriggerTime = function (value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};

/**
 * optional uint64 last_sign_time = 4;
 * @return {number}
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.getLastSignTime = function () {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};

/**
 * @param {number} value
 * @return {!proto.sgn.cbridge.v1.LatestSigners} returns this
 */
proto.sgn.cbridge.v1.LatestSigners.prototype.setLastSignTime = function (value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};

/**
 * @enum {number}
 */
proto.sgn.cbridge.v1.XferStatus = {
  UNKNOWN: 0,
  OK_TO_RELAY: 1,
  SUCCESS: 2,
  BAD_LIQUIDITY: 3,
  BAD_SLIPPAGE: 4,
  BAD_TOKEN: 5,
  REFUND_REQUESTED: 6,
  REFUND_DONE: 7,
  BAD_XFER_DISABLED: 8,
  BAD_DEST_CHAIN: 9,
  EXCEED_MAX_OUT_AMOUNT: 10,
  XFER_DELAYED: 11,
  BAD_ADDRESS: 12,
};

goog.object.extend(exports, proto.sgn.cbridge.v1);
