export const basTestNetworks = {
  bscTest: {
    name: "BSC Testnet",
    color: "#7057ff",
    chainId: 97,
    rpcUrl: "https://bsc-testnet.nodereal.io/v1/bdc0906a6f534a4598cdb24425cc855a",
    blockTime: 4000,
    iconUrl: "./bnbchain.png",
    blockDelay: 80,
    symbol: "BNB",
    blockExplorerUrl: "https://testnet.bscscan.com",
    tokenSymbolList: ["BNB", "BAS", "BUSD", "ARS", "BAS-MA", "SHELL", "GAL", "RNS", "CUBE"],
    lqMintTokenSymbolBlackList: ["SHELL", "GAL", "RNS", "CUBE"],
  },
  goerli: {
    name: "Goerli Testnet",
    color: "#3099f2",
    chainId: 5,
    rpcUrl: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
    blockTime: 15000,
    iconUrl: "./ETH.png",
    blockDelay: 4,
    symbol: "ETH",
    blockExplorerUrl: "https://goerli.etherscan.io",
    tokenSymbolList: ["CELR", "USDT", "WETH", "TCELR", "USDC", "DOMI", "MCB", "cUSD", "PSP", "LUNA", "testtoken"],
    contractAddr: "0x358234B325EF9eA8115291A8b81b7d33A2Fa762D",
    lqMintTokenSymbolBlackList: ["DOMI", "cUSD", "PSP", "LUNA", "testtoken"],
  },
  BASTestnet: {
    name: "BAS Testnet",
    color: "#7057ff",
    chainId: 14000,
    rpcUrl: "https://rpc.dev-01.bas.ankr.com/",
    blockTime: 4000,
    iconUrl: "./bas.png",
    blockDelay: 80,
    symbol: "BAS",
    blockExplorerUrl: "https://explorer.dev-01.bas.ankr.com/",
    tokenSymbolList: ["BAS", "BNB", "BUSD", "ARS"],
    lqMintTokenSymbolBlackList: [],
  },
  BASAries: {
    name: "BAS Aries Testnet",
    color: "#7057ff",
    chainId: 117,
    rpcUrl: "https://bas-aries-public.nodereal.io/",
    blockTime: 4000,
    iconUrl: "./bsc_aries.png",
    blockDelay: 80,
    symbol: "ARS",
    blockExplorerUrl: "https://bas-aries-testnet-explorer.nodereal.io/",
    tokenSymbolList: ["BNB", "BUSD", "ARS", "BAS"],
    lqMintTokenSymbolBlackList: [],
  },
  BASMA: {
    name: "Meta Apes BAS Testnet",
    color: "#7057ff",
    chainId: 15001,
    rpcUrl: "https://meta-ape-bas-testnet-01.ankr.com",
    blockTime: 4000,
    iconUrl: "./bas_apes.png",
    blockDelay: 80,
    symbol: "BAS-MA",
    blockExplorerUrl: "https://explorer.meta-ape-bas-testnet-02.ankr.com",
    tokenSymbolList: ["BNB", "BAS-MA", "SHELL"],
    lqMintTokenSymbolBlackList: [],
  },

  BASGalaxy: {
    name: "BAS Galaxy Testnet",
    color: "#3099f2",
    chainId: 230,
    rpcUrl: "https://bas-galaxy-testnet.nodereal.io",
    blockTime: 5000,
    iconUrl: "./bas_galaxy.png",
    blockDelay: 6,
    symbol: "GAL",
    gasDecimal: 18,
    blockExplorerUrl: "https://bas-galaxy-testnet-explorer.nodereal.io",
    tokenSymbolList: ["BNB", "BUSD", "GAL"],
    lqMintTokenSymbolBlackList: ["BNB", "BUSD", "GAL"],
  },
  BASRNS: {
    name: "BAS RNS Testnet",
    color: "#3099f2",
    chainId: 231,
    rpcUrl: "https://bas-rns-testnet.nodereal.io",
    blockTime: 5000,
    iconUrl: "./bas_rns.png",
    blockDelay: 6,
    symbol: "RNS",
    gasDecimal: 18,
    blockExplorerUrl: "https://bas-rns-testnet-explorer.nodereal.io",
    tokenSymbolList: ["BNB", "BUSD", "RNS"],
    lqMintTokenSymbolBlackList: ["BNB", "BUSD", "RNS"],
  },
  BASCUBE: {
    name: "BAS CUBE Testnet",
    color: "#3099f2",
    chainId: 232,
    rpcUrl: "https://bas-cube-testnet.nodereal.io",
    blockTime: 5000,
    iconUrl: "./bas_cube.png",
    blockDelay: 6,
    symbol: "CUBE",
    gasDecimal: 18,
    blockExplorerUrl: "https://bas-cube-testnet-explorer.nodereal.io",
    tokenSymbolList: ["BNB", "BUSD", "CUBE"],
    lqMintTokenSymbolBlackList: ["BNB", "BUSD", "CUBE"],
  },
};
