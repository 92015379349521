/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { ISwapToken, ISwapTokenInterface } from "../ISwapToken";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
      {
        internalType: "address[]",
        name: "",
        type: "address[]",
      },
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "swapExactTokensForTokens",
    outputs: [
      {
        internalType: "uint256[]",
        name: "",
        type: "uint256[]",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class ISwapToken__factory {
  static readonly abi = _abi;
  static createInterface(): ISwapTokenInterface {
    return new utils.Interface(_abi) as ISwapTokenInterface;
  }
  static connect(address: string, signerOrProvider: Signer | Provider): ISwapToken {
    return new Contract(address, _abi, signerOrProvider) as ISwapToken;
  }
}
